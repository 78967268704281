import { useQuery } from 'react-query';
import { OperatorsAPI } from '../../constants/API';
import axios, { AxiosResponse } from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { ticketDataType } from '../../pagesMain/Operator1Page/Operator1Page';

export const useGetRefreshEntryTicket = (
  setActiveCarId: (id: number | null) => void,
  setIsCalledClient: (status: boolean) => void,
  isWorkPlaceSelected: boolean,
  setPrintTicketId?: (id: number | null) => void
) => {
  const {
    data: entryTicketResp,
    isFetching: isLoadingEntryTicket,
    isSuccess: isEntryTicketRecieved
  } = useQuery(
    ['refreshEntryTicket'],
    () => axios.get<AxiosResponse>(OperatorsAPI.refreshTicket(), commonHeaders()),
    {
      onSuccess: (resp: AxiosResponse<ticketDataType>) => {
        if (resp.data.itemId) {
          setActiveCarId(resp.data.itemId);
          setPrintTicketId(resp.data.entryTicket?.entryTicketId);
          setIsCalledClient(true);
        } else {
          setActiveCarId(null);
          setIsCalledClient(false);
        }
      },
      enabled: isWorkPlaceSelected
    }
  );

  return { entryTicketResp, isLoadingEntryTicket, isEntryTicketRecieved };
};
