import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAnalyticsAndReportsAPI } from '../../../../constants/API';
import { commonHeaders } from '../../../../constants/HeadersAPI';

type StationReportTodayType = {
  itemType: string;
  itemsInZone: number;
  itemsInQueue: number;
  importCargo: number;
  exportCargo: number;
  avgTimeInZone: string;
  avgTimeInQueue: string;
};

export const useGetStationReportToday = () => {
  const {
    data: stationReportTodayResp,
    isFetching: isLoadingStationReportToday,
    isSuccess: isStationReportTodayRecieved
  } = useQuery(
    ['stationReportToday'],
    () =>
      axios.get<StationReportTodayType[]>(
        AdminAnalyticsAndReportsAPI.getStationReportToday,
        commonHeaders()
      ),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e) => {
            return {
              identity: e.itemType,
              locatedOnTheTerritory: e.itemsInZone,
              waitingInQueue: e.itemsInQueue,
              importOfCargo: e.importCargo,
              exportOfCargo: e.exportCargo,
              averageTimeOnTerritory: e.avgTimeInZone,
              averageTimeInQueue: e.avgTimeInQueue
            };
          });
        } else {
          return [];
        }
      }
    }
  );

  return {
    stationReportTodayResp,
    isLoadingStationReportToday,
    isStationReportTodayRecieved
  };
};
