import React, { FC } from 'react';
import classNames from 'classnames';

import classes from './ModalLoader.module.scss';

type ModalLoaderType = {
  text: string;
};

const ModalLoader: FC<ModalLoaderType> = ({ text }) => {
  return (
    <div className={classNames(classes.modal, classes.active)}>
      <div className={classes.modalContainer}>
        <div className={classes.title}>{text}</div>
        <div className={classes.spinerContainer}>
          <div className={classes.spiner}></div>
        </div>
      </div>
    </div>
  );
};

export default ModalLoader;
