import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAnalyticsAndReportsAPI } from '../../../../constants/API';
import { commonHeaders } from '../../../../constants/HeadersAPI';
import { AnalyticsAndReportsDateType } from '../../../../types/Admin/AnalyticsAndReports';

type CompaniesReportPeriodType = {
  client: string;
  importCargo: number;
  exportCargo: number;
  avgTimeInZone: string;
  avgTimeInQueue: string;
  containersNumber: number;
  platformNumber: number;
  warehousNumber: number;
};

export const useGetCompaniesReportPeriod = (date: AnalyticsAndReportsDateType) => {
  const {
    data: companiesReportPeriodResp,
    isFetching: isLoadingCompaniesReportPeriod,
    isSuccess: isCompaniesReportPeriodRecieved,
    refetch: refetchCompaniesReportPeriod
  } = useQuery(
    ['companiesReportPeriod'],
    () =>
      axios.get<CompaniesReportPeriodType[]>(
        `${AdminAnalyticsAndReportsAPI.getClientReportPeriod}?startDate=${date.startDate}&endDate=${date.endDate}`,
        commonHeaders()
      ),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e, idx) => {
            return {
              serialNumber: idx + 1,
              company: e.client,
              importOfCargo: e.importCargo,
              exportOfCargo: e.exportCargo,
              averageTimeOnTerritory: e.avgTimeInZone,
              averageTimeInQueue: e.avgTimeInQueue,
              containerQuantity: e.containersNumber,
              heavyPlatformQuantity: e.platformNumber,
              warehouseQuantity: e.warehousNumber
            };
          });
        } else {
          return [];
        }
      }
    }
  );

  return {
    companiesReportPeriodResp,
    isLoadingCompaniesReportPeriod,
    isCompaniesReportPeriodRecieved,
    refetchCompaniesReportPeriod
  };
};
