import React, { FC } from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';
import { checkIcon } from '../../../../../assets/icons';
import classes from './CompanyTable.module.scss';
import Skeleton from '../../../Skeleton/Skeleton';

export type CompanyDayTableType = {
  serialNumber: number;
  company: string;
  carNumber: string;
  entryTime: string;
  exitTime: string;
  timeOnTerritory: string;
  timeInQueue: string;
  containerNumber: string;
  isImport: boolean;
  isExport: boolean;
};

const columnSizes = {
  serialNumberWidth: 59,
  companyWidth: 561,
  carNumberWidth: 153,
  entryTimeWidth: 67,
  exitTimeWidth: 72,
  timeOnTerritoryWidth: 103,
  timeInQueueWidth: 148,
  containerNumberWidth: 218,
  isImportWidth: 95,
  isExportWidth: 104
};

const {
  serialNumberWidth,
  companyWidth,
  carNumberWidth,
  entryTimeWidth,
  exitTimeWidth,
  timeOnTerritoryWidth,
  timeInQueueWidth,
  containerNumberWidth,
  isImportWidth,
  isExportWidth
} = columnSizes;

type CompanyDayTableProps = {
  companiesReportTodayData: CompanyDayTableType[];
  isLoadingData: boolean;
};

const CompanyDayTable: FC<CompanyDayTableProps> = ({ companiesReportTodayData, isLoadingData }) => {
  const columnHelper = createColumnHelper<CompanyDayTableType>();
  const columns = [
    columnHelper.accessor('serialNumber', {
      header: () => <div className={classes.headerContentCenter}>№п/п</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: serialNumberWidth
    }),
    columnHelper.accessor('company', {
      header: () => <div className={classes.headerContent}>Юридическое лицо</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: companyWidth
    }),
    columnHelper.accessor('carNumber', {
      header: () => <div className={classes.headerContent}>Номер автомобиля</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: carNumberWidth
    }),
    columnHelper.accessor('entryTime', {
      header: () => <div className={classes.headerContentCenter}>Время въезда</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: entryTimeWidth
    }),
    columnHelper.accessor('exitTime', {
      header: () => <div className={classes.headerContentCenter}>Время выезда</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: exitTimeWidth
    }),
    columnHelper.accessor('timeOnTerritory', {
      header: () => <div className={classes.headerContentCenter}>Время на территории</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: timeOnTerritoryWidth
    }),
    columnHelper.accessor('timeInQueue', {
      header: () => <div className={classes.headerContentCenter}>Время ожидания в очереди</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: timeInQueueWidth
    }),
    columnHelper.accessor('containerNumber', {
      header: () => (
        <div className={classes.headerContent}>К-номер контейнера/ склад/тяжеловесная площадка</div>
      ),
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: containerNumberWidth
    }),
    columnHelper.accessor('isImport', {
      header: () => <div className={classes.headerContentCenter}>Ввоз груза</div>,
      cell: ({ getValue }) => {
        return getValue() ? (
          <div className={classes.columnContentCenter}>
            <img src={checkIcon as unknown as string} alt="active" />
          </div>
        ) : (
          ''
        );
      },
      size: isImportWidth
    }),
    columnHelper.accessor('isExport', {
      header: () => <div className={classes.headerContentCenter}>Вывоз груза</div>,
      cell: ({ getValue }) => {
        return getValue() ? (
          <div className={classes.columnContentCenter}>
            <img src={checkIcon as unknown as string} alt="active" />
          </div>
        ) : (
          ''
        );
      },
      size: isExportWidth
    })
  ];

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : companiesReportTodayData || []),
    [isLoadingData, companiesReportTodayData]
  );
  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}>
                      <div className={classes.thTitle}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                          }}>
                          <div className={classes.colorResizer} />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompanyDayTable;
