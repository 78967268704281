import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAnalyticsAndReportsAPI } from '../../../../constants/API';
import { commonHeaders } from '../../../../constants/HeadersAPI';

type CompaniesReportTodayType = {
  client: string;
  carNumber: string;
  timeIn: string;
  timeOut: string;
  timeInZone: string;
  timeInQueue: string;
  container: string;
  isImport: boolean;
  isExport: boolean;
};

export const useGetCompaniesReportToday = () => {
  const {
    data: companiesReportTodayResp,
    isFetching: isLoadingCompaniesReportToday,
    isSuccess: isCompaniesReportTodayRecieved
  } = useQuery(
    ['companiesReportToday'],
    () =>
      axios.get<CompaniesReportTodayType[]>(
        AdminAnalyticsAndReportsAPI.getClientReportToday,
        commonHeaders()
      ),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e, idx) => {
            return {
              serialNumber: idx + 1,
              company: e.client,
              carNumber: e.carNumber,
              entryTime: e.timeIn,
              exitTime: e.timeOut,
              timeOnTerritory: e.timeInZone,
              timeInQueue: e.timeInQueue,
              containerNumber: e.container,
              isImport: e.isImport,
              isExport: e.isExport
            };
          });
        } else {
          return [];
        }
      }
    }
  );

  return {
    companiesReportTodayResp,
    isLoadingCompaniesReportToday,
    isCompaniesReportTodayRecieved
  };
};
