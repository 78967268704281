import { useState } from 'react';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsAndReportsNavigation, SidebarNavigation } from './index';
import { adminLogo, queueAdminLogo, mailIcon, webIcon } from '../../../assets/icons/index';
import MenuButton from '../MenuButton/';
import classes from './SideBar.module.scss';
import React from 'react';
import { mail, webSite } from '../../../constants/data';
import { adminRoutesURLs } from '../../../constants/routes';

const SideBar = () => {
  const [activeDropDownItem, setDropDownItem] = useState(AnalyticsAndReportsNavigation[0].name);
  const [activeDropDownItemURL, setDropDownItemURL] = useState(
    adminRoutesURLs.analyticsAndReports.currentServicesStatus
  );
  // const [activeButton, setActiveButton] = useState()
  const navigate = useNavigate();
  const location = useLocation();
  const activeURL = useMemo<string>(() => {
    return location.pathname;
  }, [location]);

  type HandleButtonContentType = {
    name: string;
    url: string;
  };
  const handleButtonClick = (content: HandleButtonContentType) => {
    redirectToPostPage(content.url);
  };
  const handleDropDownItem = (content: string): void => {
    setDropDownItem(content);
  };

  const redirectToPostPage = (section: string) => {
    navigate(section);
  };
  return (
    <div className={classes.sidebar}>
      <div className={classes.companyLogo}>
        <img src={adminLogo} alt="tehnosky-logo" />
      </div>
      <div className={classes.sidebarHeader}>
        <img src={queueAdminLogo} alt="system-queue-logo" />
        <div className={classes.sidebarHeaderText}>
          <p>система управления очередью</p>
        </div>
      </div>
      <ul className={classes.sidebarMenu}>
        {SidebarNavigation.map((item, index) => (
          <div key={index}>
            {item.name !== 'Аналитика и отчеты' ? (
              <li>
                <MenuButton
                  content={item.name}
                  isActive={activeURL.includes(item.url)}
                  onClick={() => {
                    handleButtonClick(item);
                  }}
                />
              </li>
            ) : (
              <li id={classes.liAnalytics}>
                <div className={classes.btnAnalyticsCont}>
                  <MenuButton
                    content="Аналитика и отчеты"
                    isActive={
                      activeURL.includes(item.url) || activeURL.includes(activeDropDownItemURL)
                    }
                    onClick={() => {
                      handleButtonClick(item);
                      setDropDownItem(AnalyticsAndReportsNavigation[0].name);
                    }}
                  />
                </div>
                {activeURL.includes(
                  adminRoutesURLs.analyticsAndReports.analyticsAndReportsBase
                ) && (
                  <ul className={classes.analyticsMenu}>
                    {AnalyticsAndReportsNavigation.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          handleDropDownItem(item.name);
                          setDropDownItemURL(item.url);
                          redirectToPostPage(
                            `${adminRoutesURLs.analyticsAndReports.analyticsAndReportsBase}/${item.url}`
                          );
                        }}>
                        <div
                          className={classes.item}
                          id={activeDropDownItem === item.name ? classes.active : ''}>
                          {item.name}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            )}
          </div>
        ))}
      </ul>
      <div className={classes.sidebarFooter}>
        <div className={classes.menuFooterMail}>
          <img src={mailIcon} alt={'mail'} />
          <a href={`mailto:${mail}`}>
            <span>{mail}</span>
          </a>
        </div>
        <div className={classes.menuFooterLink}>
          <img src={webIcon as unknown as string} alt={'web'} />
          <a href="https://high-tech.by/">
            <span>{webSite}</span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default SideBar;
