import { useQuery } from 'react-query';
import axios from 'axios';
import { MonitorQueueAPI } from '../../constants/API';
import {
  MonitorsUnitType,
  ReceivedSoundArrType
} from '../../components/Main/QueuePanel/QueuePanel';
import { transformReceivedSounds } from '../../utils/QueuePage/transformReceivedSounds';

export const useQueueItems = (
  token: string | null,
  setReceivedSoundArr: (soundsArr: ReceivedSoundArrType) => void,
  isSoundPlaying: boolean
) => {
  const {
    data: queueItemsResp,
    isLoading: isLoadingQueueItems,
    isSuccess: isQueueItemsRecieved
  } = useQuery(
    ['windows'],
    () =>
      axios.get<MonitorsUnitType>(MonitorQueueAPI.getQueueItems, {
        params: {
          token: token
        }
      }),
    {
      select: ({ data }) => data,
      onSuccess: (data) => setReceivedSoundArr(transformReceivedSounds(data.items)),
      enabled: !!token && !isSoundPlaying,
      refetchInterval: 4000
    }
  );

  return { queueItemsResp, isLoadingQueueItems, isQueueItemsRecieved };
};
