import { useMutation } from 'react-query';
import { OperatorsAPI } from '../../constants/API';
import axios, { AxiosError } from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { queryClient } from '../../queryClient';
import { ErrorResponseDataType } from '../../types/Admin/CategoriesTopicsTypes';
import { AlertContext } from '../../context/AlertProvider';
import { useContext } from 'react';
import { defaultErrorText } from '../../types/Error/ErrorResponseType';
import { defaultSuccessMessage } from '../../constants/alertText';
import { AlertType } from '../../types/enums';

export type UpdateCarStatusType = {
  status: string;
  id: number;
};

export const useUpdateCarStatus = (showTicketInWorkingModal: (value: boolean) => void) => {
  const context = useContext(AlertContext);
  const {
    mutate: updateCarStatus,
    isLoading,
    isError
  } = useMutation(
    ['carStatus'],
    (data: UpdateCarStatusType) =>
      axios.put(
        OperatorsAPI.updateCarStatus(data.id),
        {
          status: data.status
        },
        commonHeaders()
      ),
    {
      onSuccess: () =>
        queryClient
          .invalidateQueries('operatorQueue')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          ),
      onError: (error: AxiosError<ErrorResponseDataType>) => {
        if (error instanceof AxiosError && error.response?.data.errorStatus) {
          context?.setAlert({ type: AlertType.Error, message: error.response?.data.message });
          if (
            error.response?.data?.errorStatus === 409 &&
            error.response?.data?.errorCode === 211
          ) {
            showTicketInWorkingModal(true);
          }
        } else {
          context?.setAlert({ type: AlertType.Error, message: defaultErrorText });
        }
      }
    }
  );

  return { updateCarStatus, isLoading, isError };
};
