import * as React from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';

import classes from './CarNumberTable.module.scss';
import { checkIcon } from '../../../../../assets/icons';
import { FC } from 'react';
import Skeleton from '../../../Skeleton/Skeleton';

type CarNumberTableType = {
  num: number;
  carNumber: string;
  date: string;
  category: string;
  company: string;
  arrivalTime: string;
  insideTerritoryTime: string;
  departureTime: string;
  cargoImport: boolean;
  cargoExport: boolean;
};

const columnSizes = {
  numSize: 110,
  carNumberSize: 200,
  dateSize: 200,
  categorySize: 500,
  companySize: 1500,
  arrivalTimeSize: 200,
  insideTerritoryTimeSize: 200,
  departureTimeSize: 200,
  cargoImportSize: 200,
  cargoExportSize: 200, //starting column size
  cargoExportMinSize: 50 //enforced during column resizing
};

const {
  numSize,
  carNumberSize,
  dateSize,
  categorySize,
  companySize,
  arrivalTimeSize,
  insideTerritoryTimeSize,
  departureTimeSize,
  cargoImportSize,
  cargoExportSize,
  cargoExportMinSize
} = columnSizes;

type CarNumberTableProps = {
  carNumberReportData: CarNumberTableType[];
  isLoadingData: boolean;
};

const CarNumberTable: FC<CarNumberTableProps> = ({ carNumberReportData, isLoadingData }) => {
  const columnHelper = createColumnHelper<CarNumberTableType>();

  const categoriesColumns = [
    columnHelper.accessor('num', {
      header: () => <div className={classes.headerContent}>№п/п</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: numSize
    }),
    columnHelper.accessor('carNumber', {
      header: () => <div className={classes.headerContent}>Номер автомобиля </div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: carNumberSize
    }),
    columnHelper.accessor('date', {
      header: () => <div className={classes.headerContent}>Дата</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: dateSize
    }),
    columnHelper.accessor('category', {
      header: () => (
        <div className={classes.headerContent}>К-номер контейнера/ склад/тяжеловесная площадка</div>
      ),
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: categorySize
    }),
    columnHelper.accessor('company', {
      header: () => <div className={classes.headerContent}>Юридическое лицо</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: companySize
    }),
    columnHelper.accessor('arrivalTime', {
      header: () => <div className={classes.headerContent}>Время заезда</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: arrivalTimeSize
    }),
    columnHelper.accessor('insideTerritoryTime', {
      header: () => <div className={classes.headerContent}>Время на территории</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: insideTerritoryTimeSize
    }),
    columnHelper.accessor('departureTime', {
      header: () => <div className={classes.headerContent}>Время выезда</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: departureTimeSize
    }),
    columnHelper.accessor('cargoImport', {
      header: () => <div className={classes.headerContent}>Ввоз груза</div>,
      cell: ({ getValue }) => {
        return getValue() ? (
          <div className={classes.columnIconContent}>
            <img src={checkIcon} alt="active" />
          </div>
        ) : (
          ''
        );
      },
      size: cargoImportSize
    }),
    columnHelper.accessor('cargoExport', {
      header: () => <div className={classes.headerContentRight}>Вывоз груза</div>,
      cell: ({ getValue }) => {
        return getValue() ? (
          <div className={classes.columnContentRight}>
            <img src={checkIcon} alt="active" />
          </div>
        ) : (
          ''
        );
      },
      size: cargoExportSize,
      minSize: cargoExportMinSize
    })
  ];

  const columns = categoriesColumns;
  const [activeRow, setActiveRow] = React.useState(null);

  const handleClick = (id: string) => {
    setActiveRow(id);
  };

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : carNumberReportData || []),
    [isLoadingData, carNumberReportData]
  );
  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <div className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: `${header.getSize()}px` }}>
                    <div className={classes.thTitle}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                        }}>
                        <div className={classes.colorResizer} />
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              onClick={() => handleClick(row.id)}
              className={activeRow === row.id ? classes.activeRow : ''}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CarNumberTable;
