import React, { useState, useEffect, FC } from 'react';
import ModalLogout from '../../Main/Modals/ModalLogout/ModalLogout';

import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { tehnoSkyOperatorLogo, userIcon } from '../../../assets/icons';

import classes from './MainHeader.module.scss';

type MainHeaderProps = {
  setIsAuthenticated: (status: boolean) => void;
  setWorkPlaceSelected: (status: boolean) => void;
};

const MainHeader: FC<MainHeaderProps> = ({ setIsAuthenticated, setWorkPlaceSelected }) => {
  const [activeLogout, setActiveLogout] = useState(false);
  const [time, setTime] = useState<string>(format(Date.now(), 'HH:mm'));
  const [date, setDate] = useState<string>(format(Date.now(), 'dd MMMM yyyy', { locale: ru }));
  const user = JSON.parse(localStorage.getItem('user'));

  const handleLogout = () => {
    setActiveLogout(true);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      setTime(format(now, 'HH:mm'));
      setDate(format(now, 'dd MMMM yyyy', { locale: ru }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <div className={classes.headerContainer}>
        <div>
          <img src={tehnoSkyOperatorLogo} alt="logo" />
        </div>
        <div className={classes.timeContainer}>
          <span className={classes.time}>{time}</span>
          <span className={classes.date}>{date}</span>
        </div>

        <div className={classes.logoutWrapper}>
          <div className={classes.logoContainer} onClick={() => handleLogout()}>
            <span className={classes.username}>{user && user.fullName}</span>
            <img src={userIcon} alt="userIcon" />
          </div>
        </div>
        {activeLogout && (
          <ModalLogout
            setIsAuthenticated={setIsAuthenticated}
            setWorkPlaceSelected={setWorkPlaceSelected}
            setActiveLogout={setActiveLogout}
            activeLogout={activeLogout}
          />
        )}
      </div>
    </div>
  );
};

export default MainHeader;
