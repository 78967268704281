import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import classes from './ModalParkingSpace.module.scss';
import { CustomTextField } from '../../CustomTextField/CustomTextField';
import { Button } from '../../Button/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ModalParkingSpaceSchema } from '../../../../schemas/ValidationSchemas/ModalParkingSpaceSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { parkingSpacesTableType } from '../../Tables/ParkingSpacesTable/ParkingSpacesTable';
import { EditParkingPlacesType } from '../../../../types/Admin/ParkingPlaces.types';

type ModalParkingSpaceProps = {
  isActive: boolean;
  setActive: (value: boolean) => void;
  data: parkingSpacesTableType;
  updateParkingPlacesQuantity: (parkingPlacesData: EditParkingPlacesType) => void;
  isLoad: boolean;
};

export const ModalParkingSpace = ({
  isActive,
  setActive,
  data,
  updateParkingPlacesQuantity,
  isLoad
}: ModalParkingSpaceProps) => {
  type FormData = yup.InferType<typeof ModalParkingSpaceSchema>;
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(ModalParkingSpaceSchema),
    mode: 'onChange',
    defaultValues: {
      inputParkingName: '',
      inputCount: '',
      inputLetter: ''
    }
  });
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (data) {
      setValue('inputParkingName', data.nomination);
      setValue('inputCount', data.quantity.toString());
      setValue('inputLetter', data.letter);
    }
  }, [data]);
  const onSubmit: SubmitHandler<FormData> = async (formData: FormData) => {
    const { inputCount } = formData;
    updateParkingPlacesQuantity({ id: data.id, requestBody: { quantity: +inputCount } });
  };
  const handleClickOutside = (event: MouseEvent): void => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setActive(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className={classNames(classes.modal, { [classes.active]: isActive })}>
      <div className={classes.modalParkingSpace} ref={modalRef}>
        <form className={classes.formContainer}>
          {isLoad && (
            <div className={classes.load}>
              <div className={classes.loadContent}>
                <p>Cохранение...</p>
              </div>
            </div>
          )}
          <h2>Места на парковке</h2>
          <div className={classes.normalModeContainer}>
            <div className={classes.nameParkingSpace}>
              <CustomTextField
                {...register('inputParkingName')}
                name="inputParkingName"
                isTextAreaDisabled={true}
                value={watch('inputParkingName')}
                labelName={'Наименование'}
                errorMessage={errors.inputParkingName?.message}
              />
            </div>
            <div className={classes.count}>
              <CustomTextField
                {...register('inputCount')}
                name="inputCount"
                value={watch('inputCount')}
                labelName={'Количество'}
                errorMessage={errors.inputCount?.message}
              />
            </div>
            <div className={classes.letter}>
              <CustomTextField
                {...register('inputLetter')}
                name="inputLetter"
                isTextAreaDisabled={true}
                value={watch('inputLetter')}
                labelName={'Буква'}
                errorMessage={errors.inputLetter?.message}
              />
            </div>
          </div>
          <div className={classes.parkingButtonsContainer}>
            <Button content={'отмена'} btnStyle={'white'} onClick={() => setActive(false)} />
            <Button
              content={'сохранить'}
              btnStyle={'blue'}
              onClick={handleSubmit(onSubmit)}
              isLoading={isLoad}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
