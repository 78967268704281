import * as yup from 'yup';

const rulesWorkplaceName: RegExp = /^(?:[А-ЯЁа-яё\d\s]{0,33}|[-,./\s]{0,48})$/;
const rulesNumber: RegExp = /^\d+$/;
const rulesIp: RegExp = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;

export const ModalWorkpaceSchema = yup.object({
  workpaceName: yup
    .string()
    .required(' ')
    .min(3, 'Наименование рабочего места должно содержать не менее 3-х символов')
    .max(33, 'Наименование рабочего места должно содержать не более 33-х символов')
    .matches(rulesWorkplaceName, {
      message: 'Введите текст корректно',
      excludeEmptyString: true
    }),
  boardNumber: yup
    .string()
    .max(3, 'Превышен лимит символов')
    .matches(rulesNumber, { message: 'Введите номер корректно', excludeEmptyString: true }),
  officeNumber: yup
    .string()
    .when('type', {
      is: (val: string) =>
        val === 'Агент с ограниченными правами' || val === 'Агент с расширенными правами',
      then: (schema) => schema.required(' ').max(3, 'Превышен лимит символов'),
      otherwise: (schema) => schema
    })
    .matches(rulesNumber, { message: 'Введите номер корректно', excludeEmptyString: true }),
  searchInputText: yup.string(),
  type: yup.string().required(' '),
  digitalDisplayIp: yup
    .string()
    .when('type', {
      is: (val: string) => val === 'Оператор на въезд',
      then: (schema) => schema.required(' '),
      otherwise: (schema) => schema
    })
    .matches(rulesIp, { message: 'Введите адрес корректно', excludeEmptyString: true }),
  users: yup.array()
});
