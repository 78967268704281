import { useQuery } from 'react-query';
import axios from 'axios';
import { TerminalServicesAPI } from '../../constants/API';
import { OrderUnitsType } from '../../types/Terminal/TerminalTypes';

export const useGetOrderUnits = (token: string | null) => {
  const {
    data: orderUnitsData,
    isLoading: isLoadingOrderUnitsData,
    isSuccess: isOrderUnitsDataReceived
  } = useQuery(
    ['getOrderUnitsInTerminal'],
    () =>
      axios.get<OrderUnitsType>(TerminalServicesAPI.getServices, {
        headers: {
          'X-API-TOKEN': token
        }
      }),
    {
      select: ({ data }) => data,
      enabled: !!token,
      refetchInterval: 4000
    }
  );
  return { orderUnitsData, isLoadingOrderUnitsData, isOrderUnitsDataReceived };
};
