import React from 'react';

import { greenEllipseIcon, redEllipseIcon } from '../../../../../assets/icons';
import classes from './QueueCategoryByttom.module.scss';

type ButtonQueueCategory = {
  placeOfArea: boolean;
  category: string;
  searchCategory: string;
  length?: number;
  onHandleCategory: (activeButton: string) => void;
  data: string;
  isDisabledButtonLoading?: boolean;
};

const ButtonQueueCategory = ({
  placeOfArea,
  category,
  searchCategory,
  length,
  data,
  onHandleCategory,
  isDisabledButtonLoading
}: ButtonQueueCategory) => {
  return (
    <button
      className={`${classes.buttonCategory} ${searchCategory === data ? classes.activeButton : ''}`}
      data-category={data}
      disabled={isDisabledButtonLoading}
      onClick={() => {
        onHandleCategory(data);
      }}>
      <div className={classes.containerBtn}>
        <span>{category}</span>
        <span className={classes.length}>{`${length ? length : ''}`}</span>
        {data ? (
          placeOfArea ? (
            <img className="ms-3" src={greenEllipseIcon} alt="greenEllipse" />
          ) : (
            <img className="ms-3" src={redEllipseIcon} alt="redEllipse" />
          )
        ) : null}
      </div>
    </button>
  );
};

export default ButtonQueueCategory;
