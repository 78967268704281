import * as React from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';

import classes from './StationTables.module.scss';
import { FC } from 'react';
import Skeleton from '../../../Skeleton/Skeleton';

type CurrentMomentTableType = {
  identity: string;
  locatedOnTheTerritory: number;
  waitingInQueue: number;
  importOfCargo: number;
  exportOfCargo: number;
  averageTimeOnTerritory: string;
  averageTimeInQueue: string;
};

const columnSizes = {
  identityWidth: 236,
  locatedOnTheTerritoryWidth: 154,
  waitingInQueueWidth: 150,
  importOfCargoWidth: 95,
  exportOfCargoWidth: 104,
  averageTimeOnTerritoryWidth: 124,
  averageTimeInQueueWidth: 867
};

const {
  identityWidth,
  locatedOnTheTerritoryWidth,
  waitingInQueueWidth,
  importOfCargoWidth,
  exportOfCargoWidth,
  averageTimeOnTerritoryWidth,
  averageTimeInQueueWidth
} = columnSizes;

type CurrentMomentTableProps = {
  stationReportTodayData: CurrentMomentTableType[];
  isLoadingData: boolean;
};

const CurrentMomentTable: FC<CurrentMomentTableProps> = ({
  stationReportTodayData,
  isLoadingData
}) => {
  const columnHelper = createColumnHelper<CurrentMomentTableType>();
  const columns = [
    columnHelper.accessor('identity', {
      header: () => <div className={classes.headerFirstColumnContent}>Наименование</div>,
      cell: ({ getValue }) => (
        <div className={classes.bodyFirstColumnContent}>
          <div className={classes.firstColumnText}>{getValue()}</div>
        </div>
      ),
      size: identityWidth
    }),
    columnHelper.accessor('locatedOnTheTerritory', {
      header: () => (
        <div className={classes.headerContentCenter}>Находятся на территории кол-во</div>
      ),
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: locatedOnTheTerritoryWidth
    }),
    columnHelper.accessor('waitingInQueue', {
      header: () => <div className={classes.headerContentCenter}>Ожидают в очереди кол-во</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: waitingInQueueWidth
    }),
    columnHelper.accessor('importOfCargo', {
      header: () => <div className={classes.headerContentCenter}>Ввоз груза</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: importOfCargoWidth
    }),
    columnHelper.accessor('exportOfCargo', {
      header: () => <div className={classes.headerContentCenter}>Вывоз груза</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: exportOfCargoWidth
    }),
    columnHelper.accessor('averageTimeOnTerritory', {
      header: () => <div className={classes.headerContent}>Время (сред.) на территории </div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: averageTimeOnTerritoryWidth
    }),
    columnHelper.accessor('averageTimeInQueue', {
      header: () => <div className={classes.headerContent}>Время (сред.) ожидания в очереди </div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: averageTimeInQueueWidth
    })
  ];

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : stationReportTodayData || []),
    [isLoadingData, stationReportTodayData]
  );
  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}>
                      <div className={classes.thTitle}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                          }}>
                          <div className={classes.colorResizer} />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CurrentMomentTable;
