import React, { FC, useEffect, useState } from 'react';
import NormalHeader from '../NormalHeader/NormalHeader';
import classes from './NormalMode.module.scss';
import classNames from 'classnames';
import { TerminalMode } from '../../../../../pagesMain/TerminalPage/TerminalPage';
import {
  CategoryTopicsType,
  CategoryType,
  OrderUnitsType,
  TopicsType
} from '../../../../../types/Terminal/TerminalTypes';
import { useGetTicket } from '../../../../../hooks/TerminalServices/useGetTicket';
import { ReactComponent as ArrowBack } from '../../../../../assets/icons/arrowBack.svg';
import TerminalModal from '../../../Modals/TerminalModal/TerminalModal';
import { useSearchParams } from 'react-router-dom';

export type languageType = 'en' | 'by' | 'ru';

type NormalModePropsType = {
  setMode: (mode: TerminalMode) => void;
  orderUnitsData: OrderUnitsType;
  tickerText?: string;
};

export const NormalMode: FC<NormalModePropsType> = ({ setMode, tickerText, orderUnitsData }) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [language, setLanguage] = useState<languageType>('ru');
  const { getTicket, isLoadingGetTicket } = useGetTicket();

  const [currentSectionsServices, setCurrentSectionsServices] = useState([]);
  const [history, setHistory] = useState<{ [key: string]: CategoryTopicsType[] }>({});
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [activeModal, setActiveModal] = useState(false);
  const [activeTicketId, setActiveTicketId] = useState<number>();

  useEffect(() => {
    if (isLoadingGetTicket) {
      setActiveModal(true);
    } else {
      setActiveModal(false);
    }
  }, [isLoadingGetTicket]);

  useEffect(() => {
    if (orderUnitsData) {
      setCurrentSectionsServices(transformOrderUnitsToArr(orderUnitsData));
    }
  }, [orderUnitsData]);

  const handleCategoryClick = (category: CategoryTopicsType) => {
    if (checkIsCategory(category)) {
      setHistory((prev) => {
        const newHistory = { ...prev };
        newHistory[currentIndex] = currentSectionsServices;
        return newHistory;
      });
      setCurrentSectionsServices([...category.topics, ...category.subCategories]);
      setCurrentIndex((prev) => prev + 1);
    } else {
      setActiveTicketId(category.id);

      if (token) {
        getTicket({ topicId: category.id, token: token });
      }
    }
  };

  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
      setCurrentSectionsServices(history[currentIndex - 1]);
    }
  };

  function transformOrderUnitsToArr(data: OrderUnitsType) {
    const topics = data.categoryView.topics || [];
    const categories = data.categoryView.categories || [];
    const processCategory = (category: CategoryTopicsType, prefix = '') => {
      const categoryId = `${prefix}${category.id}`;
      let subCategories = [];
      if (category.subCategories && category.subCategories.length > 0) {
        subCategories = category.subCategories.map((subCategory: CategoryTopicsType) => {
          return processCategory(subCategory, `${categoryId}.`);
        });
      }
      return {
        ...category,
        id: categoryId,
        subCategories: subCategories
      };
    };

    const processedCategories = categories.map((category: CategoryTopicsType) =>
      processCategory(category)
    );

    return [...topics, ...processedCategories];
  }

  function checkIsCategory(categoryOrTopic: CategoryTopicsType) {
    return (
      (categoryOrTopic.subCategories && categoryOrTopic.subCategories.length > 0) ||
      (categoryOrTopic.topics && categoryOrTopic.topics.length > 0)
    );
  }

  const getTicketName = (ticket: TopicsType | CategoryType, language: languageType): string => {
    switch (language) {
      case 'ru':
        return ticket.nameRu;
      case 'en':
        return ticket.nameEn;
      case 'by':
        return ticket.nameBe;
      default:
        return ticket.nameRu;
    }
  };

  return (
    <div className={classes.normalModeContainer}>
      <TerminalModal activeModal={activeModal} setActiveModal={setActiveModal} />
      <div className={classes.headerContainer}>
        <NormalHeader language={language} setLanguage={setLanguage} setMode={setMode} />
      </div>
      <main className={classes.normalModeMain}>
        <div className={classes.chooseServiceText}>
          {currentIndex !== 0 && (
            <button onClick={handleBack} className={classes.buttonBack}>
              <ArrowBack className={classes.backImg} />
            </button>
          )}
          <div>
            <p>ВЫБЕРИТЕ УСЛУГУ</p>
          </div>
        </div>
        <div className={classes.ticketList}>
          {currentSectionsServices.length > 0 &&
            currentSectionsServices.map((ticket: CategoryTopicsType, index: number) => (
              <button
                className={classNames(classes.ticketCard, {
                  [classes.active]: ticket.isActiveNow || checkIsCategory(ticket)
                })}
                key={`${ticket.id}_${index}`}
                onClick={() => handleCategoryClick(ticket)}
                disabled={
                  (!ticket.isActiveNow && !checkIsCategory(ticket)) ||
                  (isLoadingGetTicket && activeTicketId === ticket.id) ||
                  activeModal
                }>
                <span>{getTicketName(ticket, language)}</span>
              </button>
            ))}
        </div>
      </main>
      <footer>
        <div className={classes.normalModeFooter}>
          <div className={classes.tickerText}>{tickerText ? tickerText : ''}</div>
        </div>
      </footer>
    </div>
  );
};

export default NormalMode;
