import { useMutation } from 'react-query';
import { OperatorsAPI } from '../../constants/API';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { queryClient } from '../../queryClient';
import { useContext } from 'react';
import { AlertContext } from '../../context/AlertProvider';
import { defaultSuccessMessage } from '../../constants/alertText';
import { AlertType } from '../../types/enums';

const topicIdValue = 2;

export const useRedirectCarInQueueOut = () => {
  const context = useContext(AlertContext);
  const {
    mutate: redirectCarInQueueOut,
    isLoading,
    isError
  } = useMutation(
    ['carRedirect'],
    (id: number) =>
      axios.post(
        OperatorsAPI.redirectCarInQueueOut(id),
        { topicId: topicIdValue },
        commonHeaders()
      ),
    {
      onSuccess: () =>
        queryClient
          .invalidateQueries('operatorQueue')
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          )
    }
  );

  return { redirectCarInQueueOut, isLoading, isError };
};
