import React, { FC, useState, useEffect } from 'react';

import AgentTicket from '../../components/Main/AgentTicket/AgentTicket';
import AgentForm from '../../components/Main/AgentForm/AgentForm';
import AgentQueue from '../../components/Main/AgentQueue/AgentQueue';
import AgentButtonGroup from '../../components/Main/AgentButtunGroup/AgentButtonGroup';

import { AgentStatus, TicketStatus } from '../../types/enums';
import classes from './Agent2Page.module.scss';
import { ModalRedirection } from '../../components/Main/Modals/ModalRedirection/ModalRedirection';
import { useGetAgentQueue } from '../../hooks/Agents/useGetAgentQueue';
import { AgentsQueueType, TicketCommentsType } from '../Agent1Page/Agent1Page';
import { clientsListDataType, containersListDataType } from '../Operator1Page/Operator1Page';
import { useGetContainersList } from '../../hooks/Operators/useGetContainersList';
import { useGetClientsList } from '../../hooks/Operators/useGetClientsList';
import { useGetTicketComments } from '../../hooks/Agents/useGetTicketComments';
import { TicketType, useGetUsedTicket } from '../../hooks/Agents/useGetUsedTicket';
import { usePrintEntryTicket } from '../../hooks/Operators/usePrintEntryTicket';
import { usePostSynchronizeDataBase } from '../../hooks/Operators/usePostSynchronizeDataBase';
import { useRedirectToOperator } from '../../hooks/Agents/useRedirectToOperator';
import ModalTicketInWorking from '../../components/Main/Modals/ModalTicketInWorking/ModalTicketInWorking';
import ModalLoader from '../../components/Main/Modals/ModalLoader/ModalLoader';
import AgentsQueueNotification from '../../components/Main/AgentNotifications/AgentNotifications';
import { isNotificationEnabled } from '../../constants/API';
import classNames from 'classnames';
import { AgentRedirectButton } from '../../components/Main/AgentRedirectButton/AgentRedirectButton';

type Agent2PageProps = {
  isWorkPlaceSelected: boolean;
};

const Agent2Page: FC<Agent2PageProps> = ({ isWorkPlaceSelected }) => {
  const [activeTicket, setActiveTicket] = useState(null);
  const [agentStatus, setAgentStatus] = useState(AgentStatus.Waiting);
  const [timeToRequest, setTimeTorequest] = useState(null);
  const { agentQueueResp, isSuccessAgentQueue } = useGetAgentQueue(isWorkPlaceSelected);
  const [isAgentWorkInQueue, setIsAgentWorkInQueue] = useState(true);
  const [modalRedirectID, setModalRedirectID] = useState(null);
  const [ticketStatus, setTicketStatus] = useState(null);
  const [isActiveModalInWorking, setActiveModalInWorking] = useState<boolean>(false);
  const [isDisabledButtonLoading, setIsDisabledButtonLoading] = useState(false);

  // TICKET VIEW
  const [isTicketWindowView, setIsTicketWindowView] = useState<boolean>(true);

  const ticketViewHandler = () => {
    setIsTicketWindowView((prevState) => !prevState);
  };

  // CONTAINERS SEARCH
  const [containerName, setContainerName] = useState('');
  const { containersListResp, isContainersListRecieved } = useGetContainersList(containerName);
  const containersList = (isContainersListRecieved ? containersListResp?.data?.containers : []) as
    | containersListDataType[]
    | [];
  // COMPANIES SEARCH
  const [clientName, setClientName] = useState('');
  const { clientsListResp, isClientsListRecieved } = useGetClientsList(clientName);
  // DATABASE SYNCHRONIZATION
  const { synchronizeDataBase, isLoadingDataBaseSync } = usePostSynchronizeDataBase();
  const [isDataBaseResearchInProgress, setIsDataBaseResearchInProgress] = useState(false);
  const clientsList = (isClientsListRecieved ? clientsListResp?.data?.clients : []) as
    | clientsListDataType[]
    | [];
  const { ticketCommentsResp, isSuccessTicketComments } = useGetTicketComments(activeTicket?.id);
  const comments: TicketCommentsType = isSuccessTicketComments
    ? ticketCommentsResp?.data?.comments
    : [];
  const [refreshTicket, setRefreshTicket] = useState<TicketType | null>(null);
  const { usedTicket } = useGetUsedTicket(isWorkPlaceSelected, setRefreshTicket);
  const [entryTicket, setEntryTicket] = useState(null);
  const { redirectToOperator } = useRedirectToOperator(activeTicket?.id, setEntryTicket);
  const { printEntryTicket, isLoadingPrintEntryTicket } = usePrintEntryTicket(entryTicket);
  const agentQueue = (isSuccessAgentQueue ? agentQueueResp?.data : null) as AgentsQueueType | null;
  const [queueStatus, setQueueStatus] = useState(null);

  useEffect(() => {
    agentQueue?.activeItems?.length > 0 ? setQueueStatus(true) : setQueueStatus(false);
  }, [agentQueue]);

  useEffect(() => {
    if (agentStatus === AgentStatus.Requesting) {
      setTimeTorequest(Date.now());
    }
    if (agentStatus === AgentStatus.Waiting) {
      setTimeTorequest(null);
    }
  }, [agentStatus]);

  useEffect(() => {
    if (refreshTicket) {
      const { createdAt, itemId, name, topicName, requestedAt, status } = refreshTicket;
      const viewedTicket = { id: itemId, name, dateTime: createdAt, topicName };
      setActiveTicket(viewedTicket);
      setTimeTorequest(requestedAt);
      setTicketStatus(status);
      if (status === TicketStatus.TakeInWorking) {
        setAgentStatus(AgentStatus.Working);
      }
      if (
        status === TicketStatus.CallFirstClientInQueue ||
        status === TicketStatus.CallPriorityClientinQueue ||
        status === TicketStatus.RepaetedCallClient
      ) {
        setAgentStatus(AgentStatus.Requesting);
      }
    }
  }, [refreshTicket, usedTicket]);

  useEffect(() => {
    if (isLoadingDataBaseSync) {
      setIsDataBaseResearchInProgress(true);
    } else {
      setIsDataBaseResearchInProgress(false);
    }
  }, [isLoadingDataBaseSync]);

  useEffect(() => {
    if (isLoadingDataBaseSync || isLoadingPrintEntryTicket) {
      setIsDisabledButtonLoading(true);
    } else {
      setIsDisabledButtonLoading(false);
    }
  }, [isLoadingDataBaseSync, isLoadingPrintEntryTicket]);

  return (
    <div>
      <div className={classes.container}>
        {isNotificationEnabled && (
          <AgentsQueueNotification
            isAgentWorkInQueue={isAgentWorkInQueue}
            setIsAgentWorkInQueue={setIsAgentWorkInQueue}
          />
        )}

        <div>
          <AgentQueue
            agentTickets={agentQueue?.activeItems}
            setActiveTicket={setActiveTicket}
            activeTicket={activeTicket}
            agentStatus={agentStatus}
          />
        </div>
        <div className={classes.ticket}>
          <div className={classes.ticketContainer}>
            {activeTicket && (
              <div className={classes.ticketWrapper}>
                <div
                  className={classNames(classes.agentTicketBlock, {
                    [classes.agentTicketWrapper]: isTicketWindowView
                  })}>
                  <AgentTicket
                    comments={comments}
                    timeToRequest={timeToRequest}
                    ticket={activeTicket}
                    agentStatus={agentStatus}
                  />
                </div>

                {agentStatus === AgentStatus.Working && (
                  <div
                    className={classNames(classes.agentFormBlock, {
                      [classes.agentFormWrapper]: !isTicketWindowView
                    })}>
                    <AgentForm
                      ticketId={activeTicket.id}
                      clientsListData={clientsList}
                      containersListData={containersList}
                      setContainerName={setContainerName}
                      setClientName={setClientName}
                      printEntryTicket={printEntryTicket}
                      synchronizeDataBase={synchronizeDataBase}
                      redirectToOperator={redirectToOperator}
                      setEntryTicket={setEntryTicket}
                      entryTicket={entryTicket}
                      isDataBaseResearchInProgress={isDataBaseResearchInProgress}
                      isDisabledButtonLoading={isDisabledButtonLoading}
                    />
                  </div>
                )}

                {agentStatus === AgentStatus.Working && (
                  <AgentRedirectButton
                    content={isTicketWindowView ? 'Оформить пропуск' : 'Вернуться к талону'}
                    onClick={ticketViewHandler}
                  />
                )}
              </div>
            )}
          </div>
          <AgentButtonGroup
            queueStatus={queueStatus}
            ticketStatus={ticketStatus}
            agentStatus={agentStatus}
            handleChangeAgentStatus={setAgentStatus}
            ticketId={activeTicket && activeTicket.id}
            activeItems={agentQueue?.activeItems}
            setActiveTicket={setActiveTicket}
            setModalRedirectID={setModalRedirectID}
            setActiveModalInWorking={setActiveModalInWorking}
            isActiveModalInWorking={isActiveModalInWorking}
            setEntryTicket={setEntryTicket}
            isDisabledButtonLoading={isDisabledButtonLoading}
            setIsTicketWindowView={setIsTicketWindowView}
          />
        </div>
        <div>
          <AgentQueue agentTickets={agentQueue?.finishedItems} />
        </div>
        {modalRedirectID && (
          <ModalRedirection
            setActive={setModalRedirectID}
            itemId={activeTicket.id}
            handleChangeAgentStatus={setAgentStatus}
            setActiveTicket={setActiveTicket}
            setIsTicketWindowView={setIsTicketWindowView}
          />
        )}
        {isActiveModalInWorking && (
          <ModalTicketInWorking
            isActive={isActiveModalInWorking}
            setActive={setActiveModalInWorking}
          />
        )}
        {isLoadingDataBaseSync && <ModalLoader text="обновление базы..." />}
        {isLoadingPrintEntryTicket && <ModalLoader text="подготовка к печати..." />}
      </div>
    </div>
  );
};

export default Agent2Page;
