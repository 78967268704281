import { adminRoutesURLs } from '../../../constants/routes';

type SidebarNavigationType = {
  name: string;
  url: string;
};

// todo Hiding Analitycs and Reports
const { sectionServices, workplaces, employers, parkingSlots, advertising, userArea } =
  adminRoutesURLs;

// const { sectionServices, workplaces, employers, parkingSlots, userArea } = adminRoutesURLs;

// Переменные роутов для аналитики и отчетов
const {
  analyticsAndReportsBase,
  services,
  employersReports,
  visitors,
  station,
  company,
  carNumber,
  containerNumber,
  currentServicesStatus
} = adminRoutesURLs.analyticsAndReports;

export const SidebarNavigation: SidebarNavigationType[] = [
  {
    name: 'Категории и услуги',
    url: sectionServices
  },
  {
    name: 'Рабочие места',
    url: workplaces
  },
  {
    name: 'Сотрудники',
    url: employers
  },
  {
    name: 'Места на парковке',
    url: parkingSlots
  },
  {
    name: 'Аналитика и отчеты',
    url: `${analyticsAndReportsBase}/${currentServicesStatus}`
  },
  {
    name: 'Реклама',
    url: advertising
  },
  {
    name: 'Личный кабинет',
    url: userArea
  }
];
export const AnalyticsAndReportsNavigation: SidebarNavigationType[] = [
  {
    name: 'Текущее состояние обслуживания посетителей',
    url: currentServicesStatus
  },
  {
    name: 'По станции',
    url: station
  },
  {
    name: 'По юридическим лицам',
    url: company
  },
  {
    name: 'По № автомобиля',
    url: carNumber
  },
  {
    name: 'По № контейнера',
    url: containerNumber
  },
  {
    name: 'По услугам',
    url: services
  },
  {
    name: 'По сотрудникам',
    url: employersReports
  },
  {
    name: 'По посетителям',
    url: visitors
  }
];
