import { useQuery } from 'react-query';
import axios from 'axios';
import { MonitorQueueAPI } from '../../constants/API';
import { baseSoundURL } from '../../config';

export type QueueVideoPlaylistRespType = {
  video: VideoType[];
};

type VideoType = {
  fileId: number;
  order: number;
  filePath: string;
};

export const useGetQueueVideoPlaylist = (
  token: string | null,
  setVideoPlaylist: (playlist: string[]) => void
) => {
  const {
    data: queueVideoPlaylistResp,
    isFetching: isLoadingQueueVideoPlaylist,
    isSuccess: isQueueVideoPlaylistRecieved
  } = useQuery(
    'QueueVideoPlaylist',
    () =>
      axios.get<QueueVideoPlaylistRespType>(MonitorQueueAPI.getQueueVideoPlaylist, {
        params: {
          token: token
        }
      }),
    {
      onSuccess: (resp) =>
        setVideoPlaylist(resp.data.video.map((e) => `${baseSoundURL}${e.filePath}`)),
      refetchInterval: 30000,
      enabled: !!token
    }
  );

  return { queueVideoPlaylistResp, isLoadingQueueVideoPlaylist, isQueueVideoPlaylistRecieved };
};
