import { useMutation } from 'react-query';
import { OperatorsAPI } from '../../constants/API';
import axios, { AxiosResponse } from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { printPDF } from '../../utils/Operators/transformByteArrayPrint';
import { useContext } from 'react';
import { AlertContext } from '../../context/AlertProvider';
import { AlertType } from '../../types/enums';
import { defaultSuccessMessage } from '../../constants/alertText';

export type StatusType = {
  status: string;
};

type base64ImgTemplateType = {
  templateImage: string;
};

export const usePrintEntryTicket = (id: number) => {
  const context = useContext(AlertContext);
  const {
    mutate: printEntryTicket,
    isLoading: isLoadingPrintEntryTicket,
    isError
  } = useMutation(
    ['printEntryTicket', id],
    () => axios.put(OperatorsAPI.printEntryTicket(id), '', commonHeaders()),
    {
      onSuccess: (resp: AxiosResponse<base64ImgTemplateType>) => {
        printPDF(resp.data.templateImage);
        context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage });
      }
    }
  );

  return { printEntryTicket, isLoadingPrintEntryTicket, isError };
};
