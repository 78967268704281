import { QueueItemType } from '../../components/Main/OperatorQueue/OperatorQueue';

const filterCars = (searchText: string, searchCategory: string, listOfCars: QueueItemType[]) => {
  if (!searchText && !searchCategory) {
    return listOfCars;
  }
  if (!searchText) {
    return listOfCars.filter(({ categoryAuto }) => categoryAuto === searchCategory);
  }
  if (!searchCategory) {
    return listOfCars.filter(({ name }) => name.toUpperCase().includes(searchText.toUpperCase()));
  }
  return listOfCars
    .filter(({ name }) => name.toUpperCase().includes(searchText.toUpperCase()))
    .filter(({ categoryAuto }) => categoryAuto === searchCategory);
};

export default filterCars;
