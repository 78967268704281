import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import printIcon from '../../../assets/icons/printIcon.svg';
import { regexOperatorsFullName, regexOperatorsPhone } from '../../../constants/data';
import { topicIdValueAddCarInQueue } from '../../../hooks/Operators/useAddCarInQueue';
import {
  clientsListDataType,
  containersListDataType,
  ticketDataType
} from '../../../pagesMain/Operator1Page/Operator1Page';
import {
  FormType,
  categoryOptionsType,
  companiesOptionsType,
  containerOptionsType
} from '../../../types/Operators.types';
import { TicketStatus, TransportCategories } from '../../../types/enums';
import classes from './Permit.module.scss';
import { QueueItemType } from '../OperatorQueue/OperatorQueue';
import { UpdateCarStatusType } from '../../../hooks/Operators/useUpdateCarStatus';

type PermitProps = {
  clientsListData: clientsListDataType[];
  containersListData: containersListDataType[];
  ticketData: ticketDataType;
  activeCarStatus: string;
  selectedCarId: number | null;
  printTicketId: number | null;
  isCalledInQueue: boolean;
  isContainerOptionsSelected: boolean;
  isCalledClient: boolean;
  selectedQueueCategory: string;
  selectedCarsQueue: QueueItemType[] | [];
  isParkingSpacesAvailable: boolean;
  isDataBaseResearchInProgress: boolean;
  setIsCalledClient: (status: boolean) => void;
  setIsContainerOptionsSelected: (status: boolean) => void;
  setContainerName: (name: string) => void;
  setClientName: (name: string) => void;
  updateCarStatus: (status: UpdateCarStatusType) => void;
  redirectCarInQueueOut: (id: number) => void;
  addCarInQueue: (data: ticketDataType) => void;
  setActiveCarStatus: (status: string) => void;
  setActiveCarId: (id: number) => void;
  setPrintTicketId: (id: number | null) => void;
  printEntryTicket: () => void;
  setIsCalledInQueue: (status: boolean) => void;
  setSelectionFromQueue: (status: boolean) => void;
  synchronizeDataBase: () => void;
  setSelectedCarId: (selectedCarId: number | null) => void;
  isDisabledButtonLoading: boolean;
};

const categoryOptions = [
  {
    label: 'Контейнеровозы (K)',
    value: TransportCategories.ContainerShips
  },
  {
    label: 'Тяжеловесная площадка (Т)',
    value: TransportCategories.HeavyPlatform
  },
  {
    label: 'Склад (С)',
    value: TransportCategories.Warehouse
  }
];

const Permit: FC<PermitProps> = ({
  ticketData,
  containersListData,
  clientsListData,
  activeCarStatus,
  printTicketId,
  isCalledInQueue,
  isContainerOptionsSelected,
  isCalledClient,
  selectedQueueCategory,
  selectedCarsQueue,
  selectedCarId,
  isDataBaseResearchInProgress,
  isParkingSpacesAvailable,
  setIsCalledClient,
  setIsContainerOptionsSelected,
  setContainerName,
  setClientName,
  setActiveCarStatus,
  updateCarStatus,
  redirectCarInQueueOut,
  addCarInQueue,
  setActiveCarId,
  printEntryTicket,
  setPrintTicketId,
  setIsCalledInQueue,
  setSelectedCarId,
  synchronizeDataBase,
  setSelectionFromQueue,
  isDisabledButtonLoading
}) => {
  const defaultCategoryValue = categoryOptions[0];
  const { categorySymbol } = ticketData;
  const {
    fullName,
    isImport,
    isExport,
    isPriorityContainer,
    vehicleNumber,
    company,
    phoneNumber,
    container,
    area
  } = ticketData.entryTicket;

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
    control,
    watch
  } = useForm<FormType>({
    mode: 'onChange'
  });
  const onSubmit: SubmitHandler<FormType> = (data) => {
    const {
      categorySymbol,
      isExport,
      isImport,
      isPriorityContainer,
      phoneNumber,
      fullName,
      company,
      containerSelector,
      area,
      vehicleNumber
    } = data;
    const ticketBody = {
      topicId: topicIdValueAddCarInQueue,
      categorySymbol: categorySymbol || categoryOptions[0].value,
      entryTicket: {
        isImport: isImport,
        isExport: isExport,
        isPriorityContainer: isPriorityContainer !== undefined ? isPriorityContainer : false,
        fullName: fullName,
        vehicleNumber: vehicleNumber,
        company: company,
        container: containerSelector,
        area: area,
        phoneNumber: phoneNumber
      }
    };
    setSelectionFromQueue(true);
    addCarInQueue(ticketBody);
  };

  const nextCarBtnHandler = () => {
    setSelectedCarId(selectedCarsQueue[0].id);
    setSelectionFromQueue(false);
    updateCarStatus({ id: selectedCarsQueue[0].id, status: TicketStatus.CallFirstClientInQueue });
    setIsCalledClient(true);
  };

  const callInSelectedCarBtnHandler = () => {
    setSelectionFromQueue(false);
    updateCarStatus({ id: selectedCarId, status: TicketStatus.CallPriorityClientinQueue });
    setIsCalledClient(true);
  };

  const repeatCallSelectedCarBtnHandler = () => {
    setSelectionFromQueue(false);
    updateCarStatus({ id: selectedCarId, status: TicketStatus.RepaetedCallClient });
    setIsCalledClient(true);
  };

  const skipSelectedCarBtnHandler = () => {
    setSelectionFromQueue(true);
    updateCarStatus({ id: selectedCarId, status: TicketStatus.SkippedClient });
    resetBtnHandler();
  };

  const deleteCarFromQueueBtnHandler = () => {
    setSelectionFromQueue(true);
    updateCarStatus({ id: selectedCarId, status: TicketStatus.DeleteClientInQueue });
    resetBtnHandler();
  };

  const printEntryTicketBtnHandler = () => {
    printEntryTicket();
  };

  const resetBtnHandler = () => {
    reset({
      categorySymbol: watch('categorySymbol'),
      fullName: '',
      isImport: false,
      isExport: false,
      vehicleNumber: '',
      phoneNumber: '',
      isPriorityContainer: false,
      area: '',
      company: '',
      containerSelector: ''
    });
    setActiveCarStatus('');
    setActiveCarId(null);
    setPrintTicketId(null);
    setIsCalledInQueue(false);
    setValue('company', '');
    setIsCalledClient(false);
    setSelectedCarId(null);
  };

  const redirectBtnHandler = () => {
    setSelectionFromQueue(true);
    redirectCarInQueueOut(selectedCarId);
    resetBtnHandler();
  };

  const refreshDataBaseBtnHandler = () => {
    synchronizeDataBase();
  };

  const containersOptions = containersListData.map((e) => {
    return { ...e, label: e.name };
  });

  const activeCarContainerOptions = (str: string) => {
    return [{ label: str, name: str }];
  };

  const getContainerValue = (value: string, options) =>
    value ? options.find((option) => option.name === value) : '';

  const activeCarCompanyOptions = (str: string) => {
    return [{ label: str, name: str }];
  };

  const companiesOptions = clientsListData.map((e) => {
    return { ...e, label: e.name };
  });

  const getCompaniesValue = (value: string, options) =>
    value ? options.find((option) => option.name === value) : value;

  const getCategoryValue = (value: string) => {
    return value ? categoryOptions.find((option) => option.value === value) : defaultCategoryValue;
  };

  useEffect(() => {
    const setTicketValues = () => {
      setValue('fullName', fullName);
      setValue('isImport', isImport);
      setValue('isExport', isExport);
      setValue('isPriorityContainer', isPriorityContainer);
      setValue('vehicleNumber', vehicleNumber);
      setValue('company', company);
      setValue('phoneNumber', phoneNumber);
      setValue('containerSelector', container);
      setValue('categorySymbol', categorySymbol);
      setValue('area', area);
    };

    setTicketValues();
  }, [ticketData]);

  return (
    <form className={classes.permitWrapper} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.InputsSectionWrapper}>
        <div className={classes.headerSecton}>
          <Controller
            control={control}
            name="categorySymbol"
            render={({ field: { onChange, value } }) => (
              <div className={classes.categorySelectorWrapper}>
                <ReactSelect
                  placeholder={''}
                  isDisabled={!!activeCarStatus || isDisabledButtonLoading}
                  classNamePrefix={'operator-select'}
                  styles={{
                    control: (styles) => {
                      return {
                        ...styles,
                        borderColor: errors.categorySymbol ? 'red' : '#EFEFEF',
                        borderWidth: '2px',
                        '&:hover': {
                          boxShadow: errors.containerSelector ? 'red' : '#EFEFEF'
                        }
                      };
                    }
                  }}
                  options={categoryOptions}
                  value={getCategoryValue(value || categorySymbol)}
                  onChange={(newValue) => {
                    onChange((newValue as categoryOptionsType).value);
                    if (
                      (newValue as categoryOptionsType).value !== TransportCategories.ContainerShips
                    ) {
                      setIsContainerOptionsSelected(false);
                      setActiveCarStatus('');
                      setPrintTicketId(null);
                      setIsCalledInQueue(false);
                      reset({
                        categorySymbol: watch('categorySymbol'),
                        fullName: watch('fullName'),
                        isImport: watch('isImport'),
                        isExport: watch('isExport'),
                        vehicleNumber: watch('vehicleNumber'),
                        phoneNumber: watch('phoneNumber'),
                        isPriorityContainer: false,
                        area: '',
                        company: '',
                        containerSelector: ''
                      });
                    }
                    if (
                      (newValue as categoryOptionsType).value === TransportCategories.ContainerShips
                    ) {
                      setIsContainerOptionsSelected(true);
                      setActiveCarStatus('');
                      setPrintTicketId(null);
                      setIsCalledInQueue(false);
                      reset({
                        categorySymbol: watch('categorySymbol'),
                        fullName: watch('fullName'),
                        isImport: watch('isImport'),
                        isExport: watch('isExport'),
                        vehicleNumber: watch('vehicleNumber'),
                        phoneNumber: watch('phoneNumber'),
                        isPriorityContainer: false,
                        area: '',
                        company: '',
                        containerSelector: ''
                      });
                    }
                  }}
                />
              </div>
            )}
          />
          <h1 className={classes.topTitle}>Пропуск</h1>
          <button
            type="button"
            disabled={isDataBaseResearchInProgress || isDisabledButtonLoading}
            className={classNames(classes.formBtn, classes.blueFormBtn)}
            onClick={() => refreshDataBaseBtnHandler()}>
            ОБНОВИТЬ БАЗУ
          </button>
        </div>

        <div className={classes.checkboxSection}>
          <div className={classes.checkboxWrapper}>
            <label className={classes.operatorCheckbox}>
              <input
                type="checkbox"
                {...register('isImport', {
                  required: watch('isExport') === false,
                  disabled: !!activeCarStatus || isDisabledButtonLoading
                })}
              />
              <span
                className={classNames(classes.checkmarkActive, {
                  [classes.checkmarkUnactive]:
                    watch('isExport') === false && (errors.isExport || errors.isImport),
                  [classes.checkmarkActive]: watch('isExport') === true
                })}></span>
            </label>
            <span className={classes.checkboxLabel}>Ввоз груза</span>
          </div>
          <div className={classes.checkboxWrapper}>
            <label className={classes.operatorCheckbox}>
              <input
                type="checkbox"
                {...register('isExport', {
                  required: watch('isImport') === false,
                  disabled: !!activeCarStatus || isDisabledButtonLoading
                })}
              />
              <span
                className={classNames(classes.checkmarkActive, {
                  [classes.checkmarkUnactive]:
                    watch('isImport') === false && (errors.isExport || errors.isImport),
                  [classes.checkmarkActive]: watch('isImport') === true
                })}></span>
            </label>
            <span className={classes.checkboxLabel}>Вывоз груза</span>
          </div>
        </div>

        {isContainerOptionsSelected && (
          <div className={classes.containersSection}>
            <div className={classes.containerSelectorWrapper}>
              <span className={classes.containerLabel}>Контейнер</span>
              <Controller
                control={control}
                name="containerSelector"
                rules={{
                  required:
                    watch('categorySymbol') === TransportCategories.ContainerShips ? true : false
                }}
                render={({ field: { onChange, value } }) => (
                  <div className={classes.containerSelector}>
                    <ReactSelect
                      placeholder={''}
                      noOptionsMessage={() => 'Введите номер контейнера'}
                      isDisabled={!!activeCarStatus || isDisabledButtonLoading}
                      classNamePrefix={'operator-select'}
                      styles={{
                        control: (styles) => {
                          return {
                            ...styles,
                            borderColor: errors.containerSelector ? 'red' : '#EFEFEF',
                            borderWidth: '2px',
                            fontSize: '0.80rem',
                            '&:hover': {
                              boxShadow: errors.containerSelector ? 'red' : '#EFEFEF'
                            }
                          };
                        }
                      }}
                      options={containersOptions}
                      onInputChange={(value) => setContainerName(value)}
                      value={
                        !container
                          ? getContainerValue(value, containersOptions)
                          : getContainerValue(value, activeCarContainerOptions(container))
                      }
                      onChange={(newValue: containerOptionsType) => {
                        setValue('area', newValue.area);
                        setValue('company', newValue.client.name);
                        onChange(newValue.name);
                      }}
                    />
                  </div>
                )}
              />
            </div>
            <div className={classes.containerAreaWrapper}>
              <span className={classes.containerLabel}>Участок</span>
              <input
                readOnly={!!activeCarStatus || isDisabledButtonLoading}
                className={classNames(classes.inputOperator, classes.inputArea, {
                  [classes.errorBorder]: errors.area
                })}
                type="text"
                {...register('area', {
                  disabled:
                    watch('categorySymbol') !== TransportCategories.ContainerShips ||
                    isDisabledButtonLoading,
                  required:
                    watch('categorySymbol') === TransportCategories.ContainerShips ? true : false,
                  minLength: 1,
                  maxLength: 4
                })}
              />
            </div>
            <div className={classNames(classes.checkboxWrapper, classes.checkboxMargin)}>
              <label className={classes.operatorCheckbox}>
                <input
                  readOnly={!!activeCarStatus || isDisabledButtonLoading}
                  type="checkbox"
                  {...register('isPriorityContainer', {
                    disabled:
                      !!activeCarStatus || !isContainerOptionsSelected || isDisabledButtonLoading
                  })}
                />
                <span
                  className={classNames({
                    [classes.checkmarkUnactive]: errors.isPriorityContainer,
                    [classes.checkmarkActive]: !errors.isPriorityContainer
                  })}></span>
              </label>
              <span className={classes.checkboxLabel}>Приоритетное (только для К)</span>
            </div>
          </div>
        )}

        <div className={classes.companiesSection}>
          <span className={classes.containerLabel}>Юридическое лицо</span>
          {isContainerOptionsSelected && (
            <div className={classes.inputFullNameWrapper}>
              <input
                readOnly={true}
                disabled={isDisabledButtonLoading}
                className={classNames(classes.inputOperator, classes.inputFullName, {
                  [classes.errorBorder]: errors.company
                })}
                type="text"
                {...register('company')}
              />
            </div>
          )}

          {!isContainerOptionsSelected && (
            <Controller
              control={control}
              name="company"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <div className={classes.companiesSelectorWrapper}>
                  <ReactSelect
                    placeholder={''}
                    noOptionsMessage={() => 'Введите наименование юридического лица'}
                    isDisabled={!!activeCarStatus || isDisabledButtonLoading}
                    classNamePrefix={'operator-select'}
                    styles={{
                      control: (styles) => {
                        return {
                          ...styles,
                          borderColor: errors.company ? 'red' : '#EFEFEF',
                          borderWidth: '2px',
                          fontSize: '0.80rem',
                          '&:hover': {
                            boxShadow: errors.containerSelector ? 'red' : '#EFEFEF'
                          }
                        };
                      }
                    }}
                    options={companiesOptions}
                    onInputChange={(value) => setClientName(value)}
                    value={
                      !company
                        ? getCompaniesValue(value, companiesOptions)
                        : getCompaniesValue(value, activeCarCompanyOptions(company))
                    }
                    onChange={(newValue) => onChange((newValue as companiesOptionsType).name)}
                  />
                </div>
              )}
            />
          )}
        </div>

        <div className={classes.fullNameSection}>
          <div className={classes.inputFullNameWrapper}>
            <span className={classes.containerLabel}>ФИО</span>
            <input
              readOnly={activeCarStatus ? true : false}
              disabled={isDisabledButtonLoading}
              className={classNames(classes.inputOperator, classes.inputFullName, {
                [classes.errorBorder]: errors.fullName
              })}
              type="text"
              {...register('fullName', {
                required: true,
                min: 6,
                max: 33,
                pattern: regexOperatorsFullName
              })}
            />
          </div>
          <div className={classes.inputPhoneNumberWrapper}>
            <span className={classes.containerLabel}>Номер телефона</span>
            <input
              readOnly={activeCarStatus ? true : false}
              disabled={isDisabledButtonLoading}
              className={classNames(classes.inputOperatorPhone, classes.inputFullName, {
                [classes.errorBorder]: errors.phoneNumber
              })}
              type="text"
              {...register('phoneNumber', {
                pattern: regexOperatorsPhone,
                minLength: 7,
                maxLength: 12
              })}
            />
          </div>
        </div>

        <div className={classes.carNumberSection}>
          <div className={classes.carNumberWrapper}>
            <span className={classes.containerLabel}>Номер автомобиля</span>
            <input
              readOnly={activeCarStatus ? true : false}
              disabled={isDisabledButtonLoading}
              className={classNames(classes.inputOperator, classes.inputCarNumber, {
                [classes.errorBorder]: errors.vehicleNumber
              })}
              type="text"
              {...register('vehicleNumber', { required: true, minLength: 6, maxLength: 33 })}
            />
          </div>
        </div>

        <div className={classes.formButtonSection}>
          <button
            disabled={isCalledClient || isDisabledButtonLoading}
            onClick={() => resetBtnHandler()}
            className={classNames(classes.formBtn, classes.resetFormBtn)}
            type="button">
            ОЧИСТИТЬ ФОРМУ
          </button>
          <div className={classes.formButtonsRight}>
            <button
              disabled={!!selectedCarId || isCalledInQueue || isDisabledButtonLoading}
              className={classNames(classes.formBtn, classes.blueFormBtn)}
              type="submit">
              ДОБАВИТЬ В ОЧЕРЕДЬ
            </button>
            <button
              disabled={
                !printTicketId ||
                activeCarStatus === TicketStatus.TakeInWorking ||
                isDisabledButtonLoading
              }
              onClick={() => printEntryTicketBtnHandler()}
              className={classNames(classes.formBtn, classes.blueFormBtn)}
              type="button">
              Печать
              <img src={printIcon} alt="printIcon" />
            </button>
          </div>
        </div>
      </div>

      <div className={classes.controlPanelWrapper}>
        <div className={classes.controlPanelSection}>
          <button
            disabled={
              !!selectedCarId ||
              !selectedQueueCategory ||
              selectedCarsQueue.length === 0 ||
              !isParkingSpacesAvailable ||
              isDisabledButtonLoading
            }
            onClick={() => nextCarBtnHandler()}
            className={classes.formBigBtn}
            type="button">
            СЛЕДУЮЩИЙ
          </button>

          <div className={classes.centralPanelButtons}>
            <button
              disabled={
                !activeCarStatus ||
                activeCarStatus === TicketStatus.TakeInWorking ||
                !!isCalledClient ||
                !isParkingSpacesAvailable ||
                isDisabledButtonLoading
              }
              onClick={() => callInSelectedCarBtnHandler()}
              className={classNames(classes.formControlBtn, classes.blueFormBtn)}
              type="button">
              ВЫЗОВ
            </button>
            <button
              disabled={!isCalledClient || !isParkingSpacesAvailable || isDisabledButtonLoading}
              onClick={() => repeatCallSelectedCarBtnHandler()}
              className={classNames(classes.formControlBtn, classes.blueFormBtn)}
              type="button">
              ПОВТОРНЫЙ ВЫЗОВ
            </button>
          </div>
          <div className={classes.centralPanelButtons}>
            <button
              disabled={!isCalledClient || !isParkingSpacesAvailable || isDisabledButtonLoading}
              onClick={() => skipSelectedCarBtnHandler()}
              className={classNames(classes.formControlBtn, classes.blueTransparentFormBtn)}
              type="button">
              НЕ ПРИШЕЛ
            </button>
            <button
              disabled={
                ((!activeCarStatus || activeCarStatus === TicketStatus.TakeInWorking) &&
                  !isCalledClient) ||
                isDisabledButtonLoading
              }
              onClick={() => deleteCarFromQueueBtnHandler()}
              className={classNames(classes.formControlBtn, classes.resetFormBtn)}
              type="button">
              УДАЛИТЬ
            </button>
          </div>

          <button
            disabled={!isCalledClient || isDisabledButtonLoading}
            onClick={() => redirectBtnHandler()}
            className={classes.formBigBtn}
            type="button">
            ВЪЕЗД
          </button>
        </div>
      </div>
    </form>
  );
};

export default Permit;
