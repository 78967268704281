import React, { FC } from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';

import classes from './CompanyTable.module.scss';
import Skeleton from '../../../Skeleton/Skeleton';

export type companyPeriodTableType = {
  serialNumber: number;
  company: string;
  importOfCargo: number;
  exportOfCargo: number;
  averageTimeOnTerritory: string;
  averageTimeInQueue: string;
  containerQuantity: number;
  heavyPlatformQuantity: number;
  warehouseQuantity: number;
};

const columnSizes = {
  serialNumberWidth: 59,
  companyWidth: 682,
  importOfCargoWidth: 95,
  exportOfCargoWidth: 104,
  averageTimeOnTerritoryWidth: 124,
  averageTimeInQueueWidth: 167,
  containerQuantityWidth: 117,
  heavyPlatformQuantityWidth: 152,
  warehouseQuantityWidth: 80
};

const {
  serialNumberWidth,
  companyWidth,
  importOfCargoWidth,
  exportOfCargoWidth,
  averageTimeOnTerritoryWidth,
  averageTimeInQueueWidth,
  containerQuantityWidth,
  heavyPlatformQuantityWidth,
  warehouseQuantityWidth
} = columnSizes;

type CompanyPeriodTableProps = {
  companiesReportPeriodData: companyPeriodTableType[];
  isLoadingData: boolean;
};

const CompanyPeriodTable: FC<CompanyPeriodTableProps> = ({
  companiesReportPeriodData,
  isLoadingData
}) => {
  const columnHelper = createColumnHelper<companyPeriodTableType>();
  const columns = [
    columnHelper.accessor('serialNumber', {
      header: () => <div className={classes.headerContentCenter}>№п/п</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: serialNumberWidth
    }),
    columnHelper.accessor('company', {
      header: () => <div className={classes.headerContent}>Юридическое лицо</div>,
      cell: ({ getValue }) => <div className={classes.colunmContent}>{getValue()}</div>,
      size: companyWidth
    }),
    columnHelper.accessor('importOfCargo', {
      header: () => <div className={classes.headerContentCenter}>Ввоз груза</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: importOfCargoWidth
    }),
    columnHelper.accessor('exportOfCargo', {
      header: () => <div className={classes.headerContentCenter}>Вывоз груза</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: exportOfCargoWidth
    }),
    columnHelper.accessor('averageTimeOnTerritory', {
      header: () => <div className={classes.headerContentCenter}>Время (сред.) на территории</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: averageTimeOnTerritoryWidth
    }),
    columnHelper.accessor('averageTimeInQueue', {
      header: () => (
        <div className={classes.headerContentCenter}>Время (сред.) ожидания в очереди</div>
      ),
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: averageTimeInQueueWidth
    }),
    columnHelper.accessor('containerQuantity', {
      header: () => <div className={classes.headerContentCenter}>Контейнеры - кол-во</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: containerQuantityWidth
    }),
    columnHelper.accessor('heavyPlatformQuantity', {
      header: () => (
        <div className={classes.headerContentCenter}>Тяжеловестная площадка - кол-во</div>
      ),
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: heavyPlatformQuantityWidth
    }),
    columnHelper.accessor('warehouseQuantity', {
      header: () => <div className={classes.headerContentCenter}>Склад - кол-во</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: warehouseQuantityWidth
    })
  ];

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : companiesReportPeriodData || []),
    [isLoadingData, companiesReportPeriodData]
  );
  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}>
                      <div className={classes.thTitle}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                          }}>
                          <div className={classes.colorResizer} />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompanyPeriodTable;
