import React, { FC, useEffect, useState } from 'react';
import classes from './ContainerNumberPage.module.scss';
import ContainerNumberTable from '../../../components/Admin/Tables/AnalyticsAndReports/ContainerNumberTable/ContainerNumberTable';
import HeaderAnalyticsTables from '../../../components/Admin/HeaderAnalyticsAndReports/HeaderAnalyticsTables';
import { useGetContainerNumberReport } from '../../../hooks/Admin/AnalyticsAndReportsTables/ContainerNumberReport.ts/useGetContainerNumberReport';
import { currentDate } from '../../../utils/Admin/AnalyticsAndReports/formatAnalyticAndReportsDate';
import { saveAsCSV } from '../../../utils/Admin/AnalyticsAndReports/saveAsCSV';
import { ContainerNumberHeader } from '../../../types/enums';

const tabsList = ['Период'];
const isActiveButtonCSV = true;

const ContainerNumberPage: FC = () => {
  const [activeTab, setActiveTab] = useState(tabsList[0]);
  const [selectedStartDate, setSelectedStartDate] = useState(currentDate);
  const [selectedEndDate, setSelectedEndDate] = useState(currentDate);
  const selectedDate = {
    startDate: selectedStartDate,
    endDate: selectedEndDate
  };
  const {
    containerNumberReportResp: containerNumberReportData,
    refetchContainerNumberReport,
    isLoadingContainerNumberReport
  } = useGetContainerNumberReport(selectedDate);

  const handleSaveAsCSV = () => saveAsCSV(containerNumberReportData, ContainerNumberHeader);

  useEffect(() => {
    refetchContainerNumberReport();
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className={classes.container}>
      <HeaderAnalyticsTables
        tabs={tabsList}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        handleSaveAsCSV={handleSaveAsCSV}
        isActiveButtonCSV={isActiveButtonCSV}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        setSelectedStartDate={setSelectedStartDate}
        setSelectedEndDate={setSelectedEndDate}
        name="Отчет по № контейнера"
      />

      <ContainerNumberTable
        containerNumberReportData={containerNumberReportData}
        isLoadingData={isLoadingContainerNumberReport}
      />
    </div>
  );
};

export default ContainerNumberPage;
