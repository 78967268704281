import React, { FC, useState, useEffect } from 'react';

import QueueCategoryButtons from './Components/QueueCategoryButtons/QueueCategoryButtons';
import CarList from './Components/CarList/CarList';
import filterCars from '../../../utils/functions/filterCars';
import { searchIcon } from '../../../assets/icons';

import classes from './OperatorQueue.module.scss';

type ParkingSpacesType = {
  freeInCategoryC: number;
  freeInCategoryK: number;
  freeInCategoryT: number;
  isFreeInCategoryC: boolean;
  isFreeInCategoryK: boolean;
  isFreeInCategoryT: boolean;
};

export type QueueItemType = {
  id: number;
  name: string;
  dateTime: string;
  status: string;
  categoryAuto: string;
};

type CarCount = {
  С: number;
  К: number;
  Т: number;
  ''?: number;
};

type OperatorQueueProps = {
  queueName: string;
  queueData: QueueItemType[] | [];
  activeCarId: number;
  parkingSpaces?: ParkingSpacesType;
  isSelectionFromQueue: boolean;
  setActiveCarId: (id: number) => void;
  setActiveCarStatus: (status: string) => void;
  carsInQueue?: CarCount;
  setSelectedCarsQueue?: (val: QueueItemType[] | []) => void;
  isSelectedQueueIn?: boolean;
  setIsSelectedQueueOut?: (status: boolean) => void;
  setSelectedQueueCategory?: (selectedQueueCategory: string) => void;
  setNextQueueCarId?: (id: number | null) => void;
  setIsNextQueueCarAllowed?: (status: boolean) => void;
  isDisabledButtonLoading?: boolean;
};

const categoryButtons = [
  { category: 'Все', data: '' },
  { category: 'T', data: 'Т' },
  { category: 'C', data: 'С' },
  { category: 'K', data: 'К' }
];

const OperatorQueue: FC<OperatorQueueProps> = ({
  parkingSpaces,
  queueName,
  queueData,
  activeCarId,
  isSelectionFromQueue,
  setSelectedCarsQueue,
  setActiveCarId,
  setActiveCarStatus,
  setSelectedQueueCategory,
  carsInQueue,
  isSelectedQueueIn,
  setIsSelectedQueueOut,
  isDisabledButtonLoading
}) => {
  const [searchCar, setSearchCar] = useState('');
  const [activeFilterButton, setActiveFilterButton] = useState('');
  const [filteredCarList, setFilteredCarList] = useState(queueData);
  const par = {
    С: { freeSpase: parkingSpaces?.isFreeInCategoryC },
    Т: { freeSpase: parkingSpaces?.isFreeInCategoryT },
    К: { freeSpase: parkingSpaces?.isFreeInCategoryK }
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      const filtredCars = filterCars(searchCar, activeFilterButton, queueData);
      setFilteredCarList(filtredCars);
      if (isSelectedQueueIn) {
        setIsSelectedQueueOut(false);
        setSelectedCarsQueue(filtredCars);
        setSelectedQueueCategory(activeFilterButton);
      }
      if (!isSelectedQueueIn && setIsSelectedQueueOut) {
        setIsSelectedQueueOut(true);
      }
    }, 400);
    return () => clearTimeout(debounce);
  }, [activeFilterButton, queueData, searchCar]);

  return (
    <div className={classes.queueWrapper}>
      <div className={classes.queueName}>{queueName}</div>
      <div className={classes.search}>
        <input
          value={searchCar}
          type="text"
          autoComplete="off"
          placeholder="введите номер машины"
          onChange={(e) => setSearchCar(e.target.value)}
          className={classes.searchInput}
        />
        <img src={searchIcon} alt="search" className={classes.searchIcon} />
      </div>
      <div className={classes.btnContainer}>
        {categoryButtons.map(({ data, category }, index) => (
          <React.Fragment key={index}>
            <QueueCategoryButtons
              category={category}
              searchCategory={activeFilterButton}
              length={carsInQueue && carsInQueue[data]}
              placeOfArea={par[data]?.freeSpase}
              onHandleCategory={setActiveFilterButton}
              data={data}
              isDisabledButtonLoading={isDisabledButtonLoading}
            />
          </React.Fragment>
        ))}
      </div>
      <div className={`${classes.list}`}>
        {queueData && (
          <CarList
            carList={filteredCarList}
            activeCarId={activeCarId}
            isSelectionFromQueue={isSelectionFromQueue}
            handleClickCar={setActiveCarId}
            setActiveCarStatus={setActiveCarStatus}
            isSelectedQueueIn={isSelectedQueueIn}
            setIsSelectedQueueOut={setIsSelectedQueueOut}
          />
        )}
      </div>
    </div>
  );
};

export default OperatorQueue;
