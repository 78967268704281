import { useMutation } from 'react-query';
import { OperatorsAPI } from '../../constants/API';
import axios from 'axios';
import { commonHeaders } from '../../constants/HeadersAPI';
import { queryClient } from '../../queryClient';
import { useContext } from 'react';
import { AlertContext } from '../../context/AlertProvider';
import { AlertType } from '../../types/enums';
import { defaultSuccessMessage } from '../../constants/alertText';

export const usePostSynchronizeDataBase = () => {
  const context = useContext(AlertContext);
  const {
    mutate: synchronizeDataBase,
    isLoading: isLoadingDataBaseSync,
    isError
  } = useMutation(
    ['syncDataBase'],
    () => axios.post(OperatorsAPI.synchronizeDataBase(), '', commonHeaders()),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('operatorQueue');
        context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage });
      }
    }
  );

  return { synchronizeDataBase, isLoadingDataBaseSync, isError };
};
