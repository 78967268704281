import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { AgentAPI } from '../../constants/API';
import { commonHeaders } from '../../constants/HeadersAPI';
import { useContext } from 'react';
import { AlertContext } from '../../context/AlertProvider';
import { defaultSuccessMessage } from '../../constants/alertText';
import { AlertType } from '../../types/enums';

export type DataType = {
  topicId: number;
  categorySymbol: string;
  entryTicket: {
    isImport: boolean;
    isExport: boolean;
    isPriorityContainer: boolean;
    fullName: string;
    vehicleNumber: string;
    company?: string;
    container?: string;
    phoneNumber: string;
  };
};

export const useRedirectToOperator = (
  ticketId: number,
  setEntryTicket: (id: number | null) => void
) => {
  const client = useQueryClient();
  const context = useContext(AlertContext);
  const {
    mutate: redirectToOperator,
    isLoading: isLoadingTicketStatus,
    isError: isErrorTicketStatus,
    isSuccess: isSuccessTicketStatus
  } = useMutation(
    ['ticketStatus', ticketId],
    (data: DataType) =>
      axios.post(`${AgentAPI.changeTicketStatus}/${ticketId}/redirect`, data, commonHeaders()),
    {
      onSuccess: async (res) => {
        client
          .invalidateQueries('agentQueue')
          .then(() => setEntryTicket(res.data.entryTicketId))
          .then(() =>
            context?.setAlert({ type: AlertType.Success, message: defaultSuccessMessage })
          );
      }
    }
  );
  return { redirectToOperator, isLoadingTicketStatus, isErrorTicketStatus, isSuccessTicketStatus };
};
