import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { regexOperatorsFullName, regexOperatorsPhone } from '../../../constants/data';
import {
  clientsListDataType,
  containersListDataType,
  ticketDataType
} from '../../../pagesMain/Operator1Page/Operator1Page';
import {
  FormType,
  categoryOptionsType,
  companiesOptionsType,
  containerOptionsType
} from '../../../types/Operators.types';
import { TicketStatus, TransportCategories } from '../../../types/enums';
import classes from './PermitOut.module.scss';
import { UpdateCarStatusType } from '../../../hooks/Operators/useUpdateCarStatus';

type PermitProps = {
  clientsListData: clientsListDataType[];
  containersListData: containersListDataType[];
  ticketData: ticketDataType;
  activeCarStatus: string;
  selectedCarId: number;
  redirectCarInQueueOut: (id: number) => void;
  setActiveCarStatus: (status: string) => void;
  setActiveCarId: (id: number) => void;
  setSelectionFromQueue: (status: boolean) => void;
  updateCarStatus: (value: UpdateCarStatusType) => void;
  setContainerName: (name: string) => void;
  setClientName: (name: string) => void;
};

const categoryOptions = [
  {
    label: 'Контейнеровозы (K)',
    value: TransportCategories.ContainerShips
  },
  {
    label: 'Тяжеловесная площадка (Т)',
    value: TransportCategories.HeavyPlatform
  },
  {
    label: 'Склад (С)',
    value: TransportCategories.Warehouse
  }
];

const PermitOut: FC<PermitProps> = ({
  ticketData,
  containersListData,
  clientsListData,
  activeCarStatus,
  selectedCarId,
  updateCarStatus,
  setContainerName,
  setClientName,
  setActiveCarStatus,
  setActiveCarId
}) => {
  const [containerCompany, setContainerCompany] = useState('');
  const { categorySymbol } = ticketData;
  const {
    fullName,
    isImport,
    isExport,
    isPriorityContainer,
    vehicleNumber,
    company,
    phoneNumber,
    container,
    area
  } = ticketData.entryTicket;

  const {
    register,
    reset,
    setValue,
    formState: { errors },
    control,
    watch
  } = useForm<FormType>({
    mode: 'onChange'
  });

  const resetBtnHandler = () => {
    reset();
    setContainerCompany('');
    setActiveCarStatus('');
    setActiveCarId(null);
    setValue('company', '');
  };

  const departureFromTerritory = () => {
    updateCarStatus({ id: selectedCarId, status: TicketStatus.FinishClientInQueue });
    resetBtnHandler();
  };

  const containersOptions = containersListData.map((e) => {
    return { ...e, label: e.name };
  });

  const activeCarContainerOptions = (str: string) => {
    return [{ label: str, name: str }];
  };

  const getContainerValue = (value: string, options) =>
    value ? options.find((option) => option.name === value) : '';

  const activeCarCompanyOptions = (str: string) => {
    return [{ label: str, name: str }];
  };

  const companiesOptions = clientsListData.map((e) => {
    return { ...e, label: e.name };
  });

  const getCompaniesValue = (value: string, options) =>
    value ? options.find((option) => option.name === value) : value;

  const getCategoryValue = (value: string) =>
    value ? categoryOptions.find((option) => option.value === value) : '';

  useEffect(() => {
    const setTicketValues = () => {
      setValue('fullName', fullName);
      setValue('isImport', isImport);
      setValue('isExport', isExport);
      setValue('isPriorityContainer', isPriorityContainer);
      setValue('vehicleNumber', vehicleNumber);
      setValue('company', company);
      setValue('phoneNumber', phoneNumber);
      setValue('containerSelector', container);
      setValue('categorySymbol', categorySymbol);
      setValue('area', area);
    };

    setTicketValues();
  }, [ticketData]);

  return (
    <form className={classes.permitWrapper}>
      <div className={classes.InputsSectionWrapper}>
        <div className={classes.headerSecton}>
          <Controller
            control={control}
            name="categorySymbol"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <div className={classes.categorySelectorWrapper}>
                <ReactSelect
                  placeholder={''}
                  isDisabled={true}
                  classNamePrefix={'operator-select'}
                  styles={{
                    control: (styles) => {
                      return {
                        ...styles,
                        borderColor: errors.categorySymbol ? 'red' : '#EFEFEF',
                        borderWidth: '2px'
                      };
                    }
                  }}
                  options={categoryOptions}
                  value={
                    !categorySymbol ? getCategoryValue(value) : getCategoryValue(categorySymbol)
                  }
                  onChange={(newValue) => {
                    onChange((newValue as categoryOptionsType).value);
                    if (
                      (newValue as categoryOptionsType).value !== TransportCategories.ContainerShips
                    ) {
                      setValue('isPriorityContainer', false);
                      setContainerCompany('');
                      setValue('company', '');
                      setValue('containerSelector', '');
                      setValue('area', '');
                    }
                    if (
                      (newValue as categoryOptionsType).value === TransportCategories.ContainerShips
                    ) {
                      setValue('company', '');
                    }
                  }}
                />
              </div>
            )}
          />
          <h1 className={classes.topTitle}>Пропуск</h1>
        </div>

        <div className={classes.checkboxSection}>
          <div className={classes.checkboxWrapper}>
            <label className={classes.operatorCheckbox}>
              <input
                readOnly={activeCarStatus ? true : false}
                type="checkbox"
                {...register('isImport', {
                  required: watch('isExport') === false,
                  disabled: true
                })}
              />
              <span
                className={classNames({
                  [classes.checkmarkUnactive]: errors.isImport,
                  [classes.checkmarkActive]: !errors.isImport
                })}></span>
            </label>
            <span className={classes.checkboxLabel}>Ввоз груза</span>
          </div>
          <div className={classes.checkboxWrapper}>
            <label className={classes.operatorCheckbox}>
              <input
                readOnly={activeCarStatus ? true : false}
                type="checkbox"
                {...register('isExport', {
                  required: watch('isImport') === false,
                  disabled: true
                })}
              />
              <span
                className={classNames({
                  [classes.checkmarkUnactive]: errors.isExport,
                  [classes.checkmarkActive]: !errors.isExport
                })}></span>
            </label>
            <span className={classes.checkboxLabel}>Вывоз груза</span>
          </div>
        </div>

        <div className={classes.containersSection}>
          <div className={classes.containerSelectorWrapper}>
            <span className={classes.containerLabel}>Контейнер</span>
            <Controller
              control={control}
              name="containerSelector"
              rules={{
                required:
                  watch('categorySymbol') === TransportCategories.ContainerShips ? true : false
              }}
              render={({ field: { onChange, value } }) => (
                <div className={classes.containerSelector}>
                  <ReactSelect
                    placeholder={''}
                    isDisabled={true}
                    classNamePrefix={'operator-select'}
                    styles={{
                      control: (styles) => {
                        return {
                          ...styles,
                          borderColor: errors.containerSelector ? 'red' : '#EFEFEF',
                          borderWidth: '2px'
                        };
                      }
                    }}
                    options={containersOptions}
                    onInputChange={(value) => setContainerName(value)}
                    value={
                      !container
                        ? getContainerValue(value, containersOptions)
                        : getContainerValue(value, activeCarContainerOptions(container))
                    }
                    onChange={(newValue: containerOptionsType) => {
                      setValue('area', newValue.area);
                      setValue(
                        'company',
                        getCompaniesValue(newValue.client.name, companiesOptions)
                      );
                      setContainerCompany(newValue.client.name);
                      onChange(newValue.name);
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className={classes.containerAreaWrapper}>
            <span className={classes.containerLabel}>Участок</span>
            <input
              readOnly={activeCarStatus ? true : false}
              className={classNames(classes.inputOperator, classes.inputArea, {
                [classes.errorBorder]: errors.area
              })}
              type="text"
              {...register('area', {
                disabled: true,
                required:
                  watch('categorySymbol') === TransportCategories.ContainerShips ? true : false,
                minLength: 1,
                maxLength: 4
              })}
            />
          </div>
          <div className={classNames(classes.checkboxWrapper, classes.checkboxMargin)}>
            <label className={classes.operatorCheckbox}>
              <input
                readOnly={!!activeCarStatus}
                type="checkbox"
                {...register('isPriorityContainer', {
                  disabled: true
                })}
              />
              <span
                className={classNames({
                  [classes.checkmarkUnactive]: errors.isPriorityContainer,
                  [classes.checkmarkActive]: !errors.isPriorityContainer
                })}></span>
            </label>
            <span className={classes.checkboxLabel}>Приоритетное (только для К)</span>
          </div>
        </div>

        <div className={classes.companiesSection}>
          <span className={classes.containerLabel}>Юридическое лицо</span>
          <Controller
            control={control}
            name="company"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <div className={classes.companiesSelectorWrapper}>
                <ReactSelect
                  placeholder={''}
                  isDisabled={true}
                  classNamePrefix={'operator-select'}
                  styles={{
                    control: (styles) => {
                      return {
                        ...styles,
                        borderColor: errors.company ? 'red' : '#EFEFEF',
                        borderWidth: '2px'
                      };
                    }
                  }}
                  options={companiesOptions}
                  onInputChange={(value) => setClientName(value)}
                  value={
                    !company && !containerCompany
                      ? getCompaniesValue(value, companiesOptions)
                      : !company && containerCompany
                        ? activeCarCompanyOptions(containerCompany)
                        : getCompaniesValue(value, activeCarCompanyOptions(company))
                  }
                  onChange={(newValue) => onChange((newValue as companiesOptionsType).name)}
                />
              </div>
            )}
          />
        </div>

        <div className={classes.fullNameSection}>
          <div className={classes.inputFullNameWrapper}>
            <span className={classes.containerLabel}>ФИО</span>
            <input
              readOnly={true}
              className={classNames(classes.inputOperator, classes.inputFullName, {
                [classes.errorBorder]: errors.fullName
              })}
              type="text"
              {...register('fullName', {
                required: true,
                min: 5,
                max: 64,
                pattern: regexOperatorsFullName
              })}
            />
          </div>
          <div className={classes.inputPhoneNumberWrapper}>
            <span className={classes.containerLabel}>Номер телефона</span>
            <input
              readOnly={true}
              className={classNames(classes.inputOperatorPhone, classes.inputFullName, {
                [classes.errorBorder]: errors.phoneNumber
              })}
              type="text"
              {...register('phoneNumber', {
                pattern: regexOperatorsPhone,
                minLength: 5,
                maxLength: 16
              })}
            />
          </div>
        </div>

        <div className={classes.carNumberSection}>
          <div className={classes.carNumberWrapper}>
            <span className={classes.containerLabel}>Номер автомобиля</span>
            <input
              readOnly={true}
              className={classNames(classes.inputOperator, classes.inputCarNumber, {
                [classes.errorBorder]: errors.vehicleNumber
              })}
              type="text"
              {...register('vehicleNumber', { required: true, minLength: 4, maxLength: 16 })}
            />
          </div>
        </div>
      </div>

      <div className={classes.controlPanelSection}>
        <button
          disabled={!activeCarStatus}
          onClick={() => departureFromTerritory()}
          className={classes.formBigBtn}
          type="button">
          ВЫЕЗД
        </button>
      </div>
    </form>
  );
};

export default PermitOut;
