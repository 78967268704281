import React from 'react';
import QueueHeader from '../../components/Main/QueueHeader/QueueHeader';
import QueuePanel from '../../components/Main/QueuePanel/QueuePanel';
import classes from './QueuePage.module.scss';
import { queueScreenStarIcon } from '../../assets/icons';
import { useGetQueueTickers } from '../../hooks/MonitorQueue/useGetQueueTickers';
import { useSearchParams } from 'react-router-dom';

const QueuePage = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { queueTickersResp } = useGetQueueTickers(token);

  return (
    <div className={classes.queuePageContainer}>
      <QueueHeader />
      <QueuePanel />
      <footer className={classes.footerContainer}>
        {queueTickersResp && (
          <div className={classes.footerText}>
            {queueTickersResp.tickers.map((e, idx) => (
              <div className={classes.runningString} key={idx}>
                <img src={queueScreenStarIcon} alt="StarIcon" />
                <span>{e.text}</span>
              </div>
            ))}
          </div>
        )}
      </footer>
    </div>
  );
};

export default QueuePage;
