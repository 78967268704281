import { useQuery } from 'react-query';
import axios from 'axios';
import { AdminAnalyticsAndReportsAPI } from '../../../../constants/API';
import { commonHeaders } from '../../../../constants/HeadersAPI';
import { AnalyticsAndReportsDateType } from '../../../../types/Admin/AnalyticsAndReports';

type ContainerNumberReportType = {
  container: string;
  date: string;
  client: string;
  carNumber: string;
  timeIn: string;
  timeInZone: string;
  timeOut: string;
  isImport: boolean;
  isExport: boolean;
};

export const useGetContainerNumberReport = (date: AnalyticsAndReportsDateType) => {
  const {
    data: containerNumberReportResp,
    isFetching: isLoadingContainerNumberReport,
    isSuccess: isContainerNumberReportRecieved,
    refetch: refetchContainerNumberReport
  } = useQuery(
    ['containerNumberReport'],
    () =>
      axios.get<ContainerNumberReportType[]>(
        `${AdminAnalyticsAndReportsAPI.getContainersReport}?startDate=${date.startDate}&endDate=${date.endDate}`,
        commonHeaders()
      ),
    {
      select: ({ data }) => {
        if (data?.length) {
          return data.map((e, idx) => {
            return {
              num: idx + 1,
              containerNumber: e.container,
              date: e.date,
              company: e.client,
              carNumber: e.carNumber,
              arrivalTime: e.timeIn,
              insideTerritoryTime: e.timeInZone,
              departureTime: e.timeOut,
              cargoImport: e.isImport,
              cargoExport: e.isExport
            };
          });
        } else {
          return [];
        }
      }
    }
  );

  return {
    containerNumberReportResp,
    isLoadingContainerNumberReport,
    isContainerNumberReportRecieved,
    refetchContainerNumberReport
  };
};
