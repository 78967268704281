import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import Operator1Page from '../../pagesMain/Operator1Page/Operator1Page';
import Operator2Page from '../../pagesMain/Operator2Page/Operator2Page';
import Agent1Page from '../../pagesMain/Agent1Page/Agent1Page';
import Agent2Page from '../../pagesMain/Agent2Page/Agent2Page';
import Page404 from '../../pagesAdmin/Page404/Page404';
import { mainRoutesURLs } from '../../constants/routes';

type MainRoutesProps = {
  isWorkPlaceSelected: boolean;
};

const MainRoutes: FC<MainRoutesProps> = ({ isWorkPlaceSelected }) => {
  const { operator1PagePath, operator2PagePath, agent1PagePath, agent2PagePath } = mainRoutesURLs;

  return (
    <div>
      <Routes>
        <Route
          path={operator1PagePath}
          element={<Operator1Page isWorkPlaceSelected={isWorkPlaceSelected} />}
        />
        <Route
          path={operator2PagePath}
          element={<Operator2Page isWorkPlaceSelected={isWorkPlaceSelected} />}
        />
        <Route
          path={agent1PagePath}
          element={<Agent1Page isWorkPlaceSelected={isWorkPlaceSelected} />}
        />
        <Route
          path={agent2PagePath}
          element={<Agent2Page isWorkPlaceSelected={isWorkPlaceSelected} />}
        />
        <Route path="/*" element={<Page404 />} />
      </Routes>
    </div>
  );
};

export default MainRoutes;
