import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { adminRoutesURLs } from '../../constants/routes';
import AdvertisingPage from '../../pagesAdmin/AdvertisingPage/AdvertisingPage';
import ParkingSlotsPage from '../../pagesAdmin/ParkingSlotsPage/ParkingSlotsPage';
import EmployersPage from '../../pagesAdmin/EmployersPage/EmployersPage';
import WorkplacesPage from '../../pagesAdmin/WorkplacesPage/WorkplacesPage';
import UserAreaPage from '../../pagesAdmin/UserAreaPage/UserAreaPage';
import SectionServicesPage from '../../pagesAdmin/SectionServicesPage/SectionServicesPage';
import CurrentServicesStatusPage from '../../pagesAdmin/AnalyticsAndReportsPages/CurrentServicesStatusPage/CurrentServicesStatusPage';
import StationPage from '../../pagesAdmin/AnalyticsAndReportsPages/StationPage/StationPage';
import CompanyPage from '../../pagesAdmin/AnalyticsAndReportsPages/CompanyPage/CompanyPage';
import CarNumberPage from '../../pagesAdmin/AnalyticsAndReportsPages/CarNumberPage/CarNumberPage';
import ContainerNumberPage from '../../pagesAdmin/AnalyticsAndReportsPages/ContainerNumberPage/ContainerNumberPage';
import ServicesPage from '../../pagesAdmin/AnalyticsAndReportsPages/ServicesPage/ServicesPage';
import VisitorsPage from '../../pagesAdmin/AnalyticsAndReportsPages/VisitorsPage/VisitorsPage';
import EmployersReportsPage from '../../pagesAdmin/AnalyticsAndReportsPages/EmployersReportsPage/EmployersReportsPage';
// import Page404 from '../../pagesAdmin/Page404/Page404';

const AdminRoutes: FC = () => {
  const { sectionServices, workplaces, employers, parkingSlots, advertising, userArea } =
    adminRoutesURLs;
  const {
    analyticsAndReportsBase,
    services,
    employersReports,
    visitors,
    station,
    company,
    carNumber,
    containerNumber,
    currentServicesStatus
  } = adminRoutesURLs.analyticsAndReports;

  return (
    <div>
      <Routes>
        <Route path={sectionServices} element={<SectionServicesPage />} />
        <Route path={workplaces} element={<WorkplacesPage />} />
        <Route path={employers} element={<EmployersPage />} />
        <Route path={parkingSlots} element={<ParkingSlotsPage />} />
        <Route path={advertising} element={<AdvertisingPage />} />
        <Route path={userArea} element={<UserAreaPage />} />
        <Route path={`${analyticsAndReportsBase}/`}>
          <Route path={services} element={<ServicesPage />} />
          <Route path={employersReports} element={<EmployersReportsPage />} />
          <Route path={visitors} element={<VisitorsPage />} />
          <Route path={station} element={<StationPage />} />
          <Route path={company} element={<CompanyPage />} />
          <Route path={carNumber} element={<CarNumberPage />} />
          <Route path={currentServicesStatus} element={<CurrentServicesStatusPage />} />
          <Route path={containerNumber} element={<ContainerNumberPage />} />
        </Route>
        <Route path="/*" element={null} />
      </Routes>
    </div>
  );
};

export default AdminRoutes;
