import { useMutation } from 'react-query';
import { MonitorQueueAPI } from '../../constants/API';
import axios from 'axios';

export type UpdateSoundDataType = {
  id: number | null;
  token: string;
};

export const useUpdateSoundStatus = () => {
  const {
    mutate: updateSoundStatus,
    isLoading,
    isError
  } = useMutation(
    ['soundStatus'],
    (data: UpdateSoundDataType) =>
      axios.put(MonitorQueueAPI.putSoundStatus(data.id, data.token), ''),
    {}
  );

  return { updateSoundStatus, isLoading, isError };
};
