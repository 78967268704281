import React, { FC, useEffect, useRef, useState } from 'react';
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender
} from '@tanstack/react-table';
import { checkIcon, modifierIcon } from '../../../../assets/icons';
import classes from './ParkingSpacesTable.module.scss';
import { handleClickOutside } from '../../../../utils/functions/handleClickOutside';
import Skeleton from '../../Skeleton/Skeleton';
import { ActionMenu } from '../../ActionMenu/ActionMenuGeneral/ActionMenu';

export type parkingSpacesTableType = {
  id: number;
  nomination: string;
  letter: string;
  quantity: number;
  isActive: boolean;
};

const columnSizes = {
  nominationWidth: 405,
  letterWidth: 66,
  quantityWidth: 106,
  isActiveWidth: 94
};
type ParkingSpacesTableProps = {
  setActiveRowData: (data: parkingSpacesTableType) => void;
  setModalActive: (value: boolean) => void;
  parkingSpacesRowData: parkingSpacesTableType[];
  isLoadingData: boolean;
};

const { nominationWidth, letterWidth, quantityWidth, isActiveWidth } = columnSizes;
const columnHelper = createColumnHelper<parkingSpacesTableType>();

const ParkingSpacesTable: FC<ParkingSpacesTableProps> = ({
  setActiveRowData,
  setModalActive,
  parkingSpacesRowData,
  isLoadingData
}) => {
  const [activeRow, setActiveRow] = React.useState(null);
  const activateBtnStatus = false;
  const [openMenuId, setOpenMenuId] = useState(null);
  const menuElement = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener('mousedown', (event: MouseEvent) =>
      handleClickOutside(event, menuElement, setOpenMenuId)
    );
    return () => {
      document.removeEventListener('mousedown', (event: MouseEvent) =>
        handleClickOutside(event, menuElement, setOpenMenuId)
      );
    };
  }, [setOpenMenuId]);

  const handleButtonClick = () => {
    setModalActive(true);
    setOpenMenuId(null);
  };

  const handleClick = (id: string) => {
    setActiveRow(id);
  };
  const columns = [
    columnHelper.accessor('nomination', {
      header: () => <div className={classes.headerFirstColumnContent}>Наименование</div>,
      cell: ({ getValue, row }) => (
        <div className={classes.bodyFirstColumnContent}>
          <div className={classes.editMenuContainer}>
            <img
              src={modifierIcon}
              id={'imgMenu'}
              alt="modifierService"
              className={classes.modifierImage}
              onClick={() => {
                if (openMenuId === row.original.id) {
                  setOpenMenuId(null);
                } else {
                  setOpenMenuId(row.original.id);
                }
                setActiveRowData(row.original);
              }}
            />
            {openMenuId === row.original.id && (
              <div ref={menuElement} className={classes.actionMenuContainer}>
                <ActionMenu editOnClick={handleButtonClick} isActivate={activateBtnStatus} />
              </div>
            )}
          </div>
          <div className={classes.firstColumnText}>{getValue()}</div>
        </div>
      ),
      size: nominationWidth
    }),
    columnHelper.accessor('letter', {
      header: () => <div className={classes.headerContentCenter}>Буква</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: letterWidth
    }),
    columnHelper.accessor('quantity', {
      header: () => <div className={classes.headerContentCenter}>Количество</div>,
      cell: ({ getValue }) => <div className={classes.columnContentCenter}>{getValue()}</div>,
      size: quantityWidth
    }),
    columnHelper.accessor('isActive', {
      header: () => <div className={classes.headerContentCenter}>Активный</div>,
      cell: ({ getValue }) => {
        return getValue() ? (
          <div className={classes.columnContentCenter}>
            <img src={checkIcon as unknown as string} alt="active" />
          </div>
        ) : (
          ''
        );
      },
      size: isActiveWidth
    })
  ];

  const tableData = React.useMemo(
    () => (isLoadingData ? Array(5).fill({}) : parkingSpacesRowData || []),
    [isLoadingData, parkingSpacesRowData]
  );
  const tableColumns = React.useMemo(
    () =>
      isLoadingData
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />
          }))
        : columns,
    [isLoadingData, columns]
  );

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    getCoreRowModel: getCoreRowModel()
  });
  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <table className={classes.tableParkingSpace}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: `${header.getSize()}px` }}>
                      <div className={classes.thTitle}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        <div
                          {...{
                            onDoubleClick: () => header.column.resetSize(),
                            onMouseDown: header.getResizeHandler(),
                            className: `resizer ltr ${header.column.getIsResizing() ? 'isResizing' : ''}`
                          }}>
                          <div className={classes.colorResizer} />
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                onClick={() => handleClick(row.id)}
                className={activeRow === row.id ? classes.activeRow : ''}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ParkingSpacesTable;
